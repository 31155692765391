import React from "react";

export default function TermsAndConditionsBn() {
  return (
    <div className="my-4">
      <div>
        <h2>অটোমেশন সার্ভিসেস লিমিটেডের শর্তাবলী এবং বিধিনিষেধ</h2>
        <p>
          <span className="font-weight-bold">সর্বশেষ আপডেট:</span> ফেব্রুয়ারি
          ১০, ২০২৫
        </p>
      </div>

      <hr className="opacity-25" />

      <div>
        <h3>ভূমিকা</h3>
        <p>
          স্বাগতম{" "}
          <span className="font-weight-bold">অটোমেশন সার্ভিসেস লিমিটেড</span>{" "}
          ("কোম্পানি," "আমরা," "আমাদের," "আমাদেরকে")-তে। আমাদের ওয়েবসাইট{" "}
          <a href="https://www.automationservicesbd.com" target="_blank">
            অটোমেশন সার্ভিসেস লিমিটেড
          </a>{" "}
          এবং পরিষেবাগুলি ব্যবহার করার মাধ্যমে আপনি এই শর্তাবলী এবং বিধিনিষেধ
          মেনে চলতে সম্মত হচ্ছেন। যদি আপনি এই শর্তাবলীর সাথে একমত না হন, অনুগ্রহ
          করে আমাদের পরিষেবা ব্যবহার করবেন না।
        </p>
      </div>

      <div>
        <h3>পরিষেবা</h3>
        <p>
          অটোমেশন সার্ভিসেস লিমিটেড তথ্য প্রযুক্তি সমাধান, অটোমেশন পরিষেবা এবং
          অন্যান্য প্রযুক্তি-সংক্রান্ত পরিষেবা প্রদান করে। আমাদের পরিষেবা
          ব্যবহারের শর্তাবলী নিম্নরূপ:
        </p>
        <ul>
          <li>পরিষেবা অবশ্যই আইনসঙ্গত ও নৈতিকভাবে ব্যবহার করতে হবে।</li>
          <li>
            আমরা যে কোনো সময় আমাদের পরিষেবা পরিবর্তন, স্থগিত বা বন্ধ করার
            অধিকার সংরক্ষণ করি।
          </li>
        </ul>
      </div>

      <div>
        <h3>ব্যবহারকারীর দায়িত্ব</h3>
        <ul>
          <li>আমাদের পরিষেবা কোনো অবৈধ কাজে ব্যবহার করা যাবে না।</li>
          <li>
            ব্যবহারকারী নিশ্চিত করবেন যে, তারা সঠিক ও সত্য তথ্য প্রদান করেছেন।
          </li>
          <li>
            পরিষেবার অপব্যবহারের কারণে ব্যবহারকারীর অ্যাকাউন্ট স্থগিত বা বাতিল
            করা হতে পারে।
          </li>
        </ul>
      </div>

      <div>
        <h3>মেধাস্বত্ব অধিকার</h3>
        <ul>
          <li>
            আমাদের ওয়েবসাইটের সমস্ত বিষয়বস্তু, লোগো এবং ট্রেডমার্ক অটোমেশন
            সার্ভিসেস লিমিটেডের সম্পত্তি।
          </li>
          <li>
            পূর্ব অনুমতি ছাড়া আমাদের কনটেন্ট অনুলিপি, বিতরণ বা পরিবর্তন করা যাবে
            না।
          </li>
        </ul>
      </div>

      <div>
        <h3>মূল্য নির্ধারণ ও অর্থপ্রদান</h3>
        <ul>
          <li>
            আমাদের পরিষেবার মূল্য ওয়েবসাইটে তালিকাভুক্ত বা চুক্তি অনুযায়ী
            নির্ধারিত হয়।
          </li>
          <li>নির্দিষ্ট শর্তাবলী অনুযায়ী অর্থপ্রদান সম্পন্ন করতে হবে।</li>
          <li>আমরা যে কোনো সময় মূল্য পরিবর্তন করার অধিকার সংরক্ষণ করি।</li>
        </ul>
      </div>

      <div>
        <h3>দায়বদ্ধতার সীমাবদ্ধতা</h3>
        <ul>
          <li>
            আমরা নিরবিচ্ছিন্ন বা ত্রুটিমুক্ত পরিষেবা প্রদানের নিশ্চয়তা দিচ্ছি
            না।
          </li>
          <li>
            আমাদের পরিষেবা ব্যবহারের কারণে কোনো সরাসরি বা পরোক্ষ ক্ষতির জন্য
            আমরা দায়ী থাকবো না।
          </li>
        </ul>
      </div>

      <div>
        <h3>গোপনীয়তা নীতি</h3>
        <ul>
          <li>
            আমাদের পরিষেবা ব্যবহারের ক্ষেত্রে আমাদের{" "}
            <a
              href="https://www.automationservicesbd.com/#/privacy-policy"
              target="_blank"
            >
              গোপনীয়তা নীতি
            </a>{" "}
            প্রযোজ্য।
          </li>
          <li>
            আমরা প্রযোজ্য আইন অনুযায়ী ব্যবহারকারীর ডাটা সংগ্রহ ও সংরক্ষণ করি।
          </li>
        </ul>
      </div>

      <div>
        <h3>তৃতীয় পক্ষের লিংক</h3>
        <p>
          আমাদের ওয়েবসাইটে তৃতীয় পক্ষের ওয়েবসাইটের লিংক থাকতে পারে। আমরা সেই
          ওয়েবসাইটগুলোর বিষয়বস্তু বা নীতির জন্য দায়ী নই।
        </p>
      </div>

      <div>
        <h3>পরিষেবা বাতিলকরণ</h3>
        <p>
          যদি কোনো ব্যবহারকারী এই শর্তাবলী লঙ্ঘন করেন, তাহলে আমরা তাদের পরিষেবা
          বাতিল বা সীমিত করার অধিকার সংরক্ষণ করি।
        </p>
      </div>

      <div>
        <h3>শর্তাবলীর পরিবর্তন</h3>
        <p>
          আমরা যে কোনো সময় এই শর্তাবলী আপডেট করতে পারি। পরিবর্তনের পর পরিষেবা
          চালিয়ে গেলে, ব্যবহারকারী নতুন শর্তাবলী মেনে নিয়েছেন বলে ধরা হবে।
        </p>
      </div>

      <div>
        <h3>আইন এবং বিচারব্যবস্থা</h3>
        <p>
          এই শর্তাবলী <span className="font-weight-bold">বাংলাদেশের আইন</span>{" "}
          দ্বারা পরিচালিত হবে এবং এই বিষয়ে যে কোনো বিরোধ বাংলাদেশের আদালতে
          নিষ্পত্তি হবে।
        </p>
      </div>

      <div>
        <h3>যোগাযোগ করুন</h3>
        <p>
          যদি আপনার এই শর্তাবলী সম্পর্কে কোনো প্রশ্ন থাকে, তাহলে আমাদের সাথে
          যোগাযোগ করুন:
        </p>
        <ul>
          <li>ইমেইল: info@automationservicesbd.com</li>
          <li>ফোন: 880 1847 364080</li>
        </ul>
      </div>
    </div>
  );
}
