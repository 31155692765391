import React from "react";

import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

import flutter from "../../assets/images/technology/flutter.svg";
import spring from "../../assets/images/technology/spring.svg";
import struts from "../../assets/images/technology/struts.svg";
import firebase from "../../assets/images/technology/firebase.svg";
import dotnet from "../../assets/images/technology/dotnet.svg";
import magento from "../../assets/images/technology/magento.svg";

import cyrptolive from "../../assets/images/portfolio/app/cyrptolive.webp";
import transactease from "../../assets/images/portfolio/app/transactease.webp";
import bitcoin from "../../assets/images/portfolio/app/bitcoin.webp";
import fdmapp from "../../assets/images/portfolio/app/fdmapp.webp";
import prepaidmeter from "../../assets/images/portfolio/app/prepaidmeter.webp";
import delivery from "../../assets/images/portfolio/app/delivery.webp";
import rhythmreach from "../../assets/images/portfolio/app/rhythmreach.webp";
import fpl from "../../assets/images/portfolio/app/fpl.webp";

const Mobileapp = () => {
  return (
    <div>
      <Row className="mt-5 pt-5">
        <Col md={7} className="mt-3">
          <h2 className="mt-5">Bitcoin Canvas</h2>

          <p className="pe-5">
            Bitcoin Canvas is your gateway to the world of cryptocurrency
            investment, offering users a secure platform to build and manage
            their crypto portfolios. Seamlessly buy, sell, and store various
            cryptocurrencies with ease, empowering investors to navi- gate the
            digital asset landscape confi- dently.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>

          <div className="blog-tech">
            <span>
              <img src={flutter} alt="flutter" />
            </span>
            <p>Flutter</p>
          </div>
          <div className="blog-tech">
            <span>
              <img src={spring} alt="spring  boot" />
            </span>
            <p>Spring Boot</p>
          </div>
          <div className="clearfix"></div>
        </Col>

        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={bitcoin} className="portimg" alt="Bitcoin Canvas" />{" "}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img
                src={fdmapp}
                className="portimg"
                alt="BPDB Field Data Management"
              />{" "}
            </div>
          </div>
        </Col>
        <Col md={7} className="mt-3 ps-5">
          <h2 className="mt-5">BPDB Field Data Management</h2>

          <p className="pe-5">
            BPDB Field Data Management app offers an intuitive interface to
            efficiently manage and track electrical meter replacements. With
            advanced features, it streamlines processes, monitors meter statuses
            in real-time, and generates comprehensive reports, optimizing
            utility management. Enhance efficiency and accuracy with FDM for
            seamless field operations.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>

          <div className="blog-tech">
            <span>
              <img src={flutter} alt="flutter" />
            </span>
            <p>Flutter</p>
          </div>
          <div className="blog-tech">
            <span>
              <img src={spring} alt="spring  boot" />
            </span>
            <p>Spring Boot</p>
          </div>
          <div className="blog-tech">
            <span>
              <img src={struts} alt="struts" />
            </span>
            <p>Struts</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={7} className="mt-3">
          <h2 className="mt-5">BPDB Prepaid Meter</h2>

          <p className="pe-5">
            BPDB Prepaid Meter app offers an intuitive interface to efficiently
            manage and track electrical meter replacements. With advanced
            features, it streamlines processes, monitors meter statuses in
            real-time, and generates comprehensive reports, optimizing utility
            management. Enhance efficiency and accuracy with FDM for seamless
            field operations.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>

          <div className="blog-tech">
            <span>
              <img src={flutter} alt="flutter" />
            </span>
            <p>Flutter</p>
          </div>
          <div className="blog-tech">
            <span>
              <img src={spring} alt="spring  boot" />
            </span>
            <p>Spring Boot</p>
          </div>
          <div className="clearfix"></div>
        </Col>

        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img
                src={prepaidmeter}
                className="portimg"
                alt="BPDB Prepaid Meter"
              />{" "}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={delivery} className="portimg" alt="delivery" />{" "}
            </div>
          </div>
        </Col>
        <Col md={7} className="mt-3 ps-5">
          <h2 className="mt-5">Delivery App</h2>

          <p className="pe-5">
            Donate essentials effortlessly with our app—find nearby mosques or
            homes on the google map, and send dates or water with a few taps.
            Secure payments make giving quick and easy. Launching in Dubai, UAE!
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>

          <div className="blog-tech">
            <span>
              <img src={flutter} alt="flutter" />
            </span>
            <p>Flutter</p>
          </div>
          <div className="blog-tech">
            <span>
              <img src={firebase} alt="firebase" />
            </span>
            <p>Firebase</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={7} className="mt-3">
          <h2 className="mt-5">CryptoLive</h2>

          <p className="pe-5">
            CryptoLive is your gateway to the world of cryptocurrency
            investment, offering users a secure platform to build and manage
            their crypto portfolios. Seamlessly buy, sell, and store various
            cryptocurrencies with ease, empowering investors to navi- gate the
            digital asset landscape confi- dently.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>

          <div className="blog-tech">
            <span>
              <img src={dotnet} alt="dotnet" />
            </span>
            <p>ASP.NET MVC</p>
          </div>
          <div className="blog-tech">
            <span>
              <img src={magento} alt="magento" />
            </span>
            <p>Magento</p>
          </div>
          <div className="clearfix"></div>
        </Col>

        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={cyrptolive} className="portimg" alt="CryptoLive" />{" "}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={transactease} className="portimg" alt="TransactEase" />{" "}
            </div>
          </div>
        </Col>
        <Col md={7} className="mt-3 ps-5">
          <h2 className="mt-5">TransactEase</h2>

          <p className="pe-5">
            TranSactEase is your all-in-one financial transaction app, offering
            seamless money transfers and bill payments in one conven- ient
            platform. Simplify your financial life with TranSactEase.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>

          <div className="blog-tech">
            <span>
              <img src={dotnet} alt="dotnet" />
            </span>
            <p>ASP.NET MVC</p>
          </div>
          <div className="blog-tech">
            <span>
              <img src={magento} alt="magento" />
            </span>
            <p>Magento</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={7} className="mt-3">
          <h2 className="mt-5">Rhythm Reach</h2>

          <p className="pe-5">
            RhythmReach is a cutting-edge platform designed to empower musicians
            and music enthusiasts alike. Seamlessly connect with fans, manage
            bookings, and col- laborate with industry professionals. Whether
            you're an artist or an administrator RhythmReach is your go-to
            platform for navigating the dynamic world of music.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>

          <div className="blog-tech">
            <span>
              <img src={dotnet} alt="dotnet" />
            </span>
            <p>ASP.NET MVC</p>
          </div>
          <div className="blog-tech">
            <span>
              <img src={magento} alt="magento" />
            </span>
            <p>Magento</p>
          </div>
          <div className="clearfix"></div>
        </Col>

        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={rhythmreach} className="portimg" alt="Rhythm Reach" />{" "}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={fpl} className="portimg" alt="FantasyNet EPL" />{" "}
            </div>
          </div>
        </Col>
        <Col md={7} className="mt-3 ps-5">
          <h2 className="mt-5">FantasyNet EPL</h2>

          <p className="pe-5">
            FantasyNet EPL offers fans a compre- hensive platform to track their
            fantasy football league stats, player perfor- mances, and more. Stay
            ahead of the game with real-time updates and analysis, making
            informed decisions to lead your team to victory in the English Pre-
            mier League.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>

          <div className="blog-tech">
            <span>
              <img src={flutter} alt="flutter" />
            </span>
            <p>Flutter</p>
          </div>
          <div className="blog-tech">
            <span>
              <img src={spring} alt="spring boot" />
            </span>
            <p>Spring Boot</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>
    </div>
  );
};

export default Mobileapp;
