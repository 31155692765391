import React from "react";
import { NavLink } from "react-router-dom";
import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import { FaCalendarAlt, FaUserAlt, FaArrowRight } from "react-icons/fa";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import blog1 from "../../assets/images/view/blog1.jpg";
import blog2 from "../../assets/images/view/blog2.jpg";

const Blog = () => {
  const options4 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <Common>
      <section className="all-header"></section>

      <section className="all-company-feature bg-deep-gray bg-deep-gray2 mt-3 pt-3 bg-white certification-slide blog-wr">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4">
              <OwlCarousel
                className="owl-theme slider-custom slider-custom-two blog-all block-crou"
                {...options4}
                loop
                margin={20}
                nav
              >
                <div class="item">
                  <Row>
                    <Col md={7}>
                      <ul className="blog-ul">
                        <li>
                          <FaCalendarAlt />
                          March 24, 2023
                        </li>
                        <li>
                          <FaUserAlt />
                          by Manek
                        </li>
                      </ul>

                      <h2>Differences Between MongoDB & MySQL</h2>

                      <p className="pe-5">
                        When it comes to database management systems, MySQL and
                        MongoDB are certainly the most popular among developers.
                        Relational systems such as MySQL use tables (containing
                        rows and columns) to store data and make use of
                        structured query languages (such as SQL) for data
                        access. An SQL query generated...
                      </p>
                      <Col md={12} className="text-start mt-4">
                        <NavLink exact to="/blog-details">
                          <Button variant="primary btn-custom mt-3 btn-big w260 w190">
                            Read More
                            <FaArrowRight className="ms-5" />
                          </Button>
                        </NavLink>
                      </Col>
                    </Col>

                    <Col md={5} className="mt-4 mb-4">
                      <div className="company-slide">
                        <div className="slider-icon2">
                          <img src={blog1} alt="blog" />{" "}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div class="item">
                  <Row>
                    <Col md={7}>
                      <ul className="blog-ul">
                        <li>
                          <FaCalendarAlt />
                          March 24, 2023
                        </li>
                        <li>
                          <FaUserAlt />
                          by Manek
                        </li>
                      </ul>

                      <h2 className="mt-5">
                        How to Create a Responsive Website Using Full-Stack
                        Development
                      </h2>

                      <p className="pe-5">
                        A full-stack developer works on both the front and back
                        end of a web application. The front end of a website is
                        in charge of how it looks and feels visually, whereas
                        the back end is in charge of the technology and logic
                        that runs the site from the backend. In order for a
                        website to dynamically adj...
                      </p>
                      <Col md={12} className="text-start mt-4">
                        <NavLink exact to="/blog-details">
                          <Button variant="primary btn-custom mt-3 btn-big w260 w190">
                            Read More
                            <FaArrowRight className="ms-5" />
                          </Button>
                        </NavLink>
                      </Col>
                    </Col>

                    <Col md={5} className="mt-4 mb-4">
                      <div className="company-slide">
                        <div className="slider-icon2">
                          <img src={blog2} alt="blog" />{" "}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default Blog;
