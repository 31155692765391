import React from "react";

import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";

import {
  FaCheckCircle,
  FaCommentDollar,
  FaHandPointRight,
  FaSearchengin,
} from "react-icons/fa";
import { TbReportAnalytics, TbSocial } from "react-icons/tb";
import { BiBookContent } from "react-icons/bi";
import { MdOutlineEmail } from "react-icons/md";

import service1 from "../../assets/images/view/service1.svg";
import service2 from "../../assets/images/view/service2.svg";

import ContactForm from "../../components/ContactForm";

const DigitalMarketing = () => {
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>Digital Marketing</strong>
                </h1>
                <p className="text-white p20 mb-4">
                  In the current digital environment, successful marketing is
                  essential to company success. Automation Services Limited
                  (ASL) is an expert in all-encompassing digital marketing
                  tactics that increase brand awareness and encourage
                  interaction. Our knowledgeable staff creates solutions that
                  are specifically suited to your needs, from email campaigns
                  and social media management to SEO and content marketing. We
                  optimize your web presence and improve consumer interactions
                  by using data-driven insights. You may improve your brand,
                  draw in new clients, and accomplish steady growth in a
                  cutthroat industry by working with ASL as your digital
                  marketing partner.
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Targeted Campaigns</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Engaging Content Creation</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Social Media Management</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Data-Driven Strategies</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Maximize ROI</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>On-Time Project Delivery </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>

      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
              <h2>Our Digital Marketing Services</h2>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Search Engine Optimization (SEO)</h5>
                    <p className="mb-0">
                      Enhancing website visibility on search engines through
                      keyword research, on-page optimization, and link-building
                      strategies. Effective SEO helps attract organic traffic
                      and improve search rankings, driving more potential
                      customers to your business.
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Social Media Marketing</h5>
                    <p className="mb-0">
                      Leveraging social media platforms to promote brand
                      awareness, engage with customers, and drive traffic. This
                      includes creating targeted campaigns, managing social
                      media accounts, and analyzing engagement metrics to
                      optimize strategies.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <TbSocial />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={service1} alt="service" />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <BiBookContent />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Content Marketing</h5>
                    <p className="mb-0 pb-3">
                      Developing and distributing valuable content to attract
                      and engage a target audience. This approach includes blog
                      posts, articles, videos, and infographics that inform,
                      entertain, and establish authority in your industry.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Pay-Per-Click Advertising (PPC)</h5>
                    <p className="mb-0">
                      Implementing targeted advertising campaigns where
                      businesses pay for each click on their ads. This strategy
                      allows for immediate visibility on search engines and
                      social media, driving traffic while controlling costs
                      through budget management.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaCommentDollar />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <MdOutlineEmail />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Email Marketing</h5>
                    <p className="mb-0 pb-4">
                      Creating targeted email campaigns to nurture leads and
                      engage existing customers. This includes personalized
                      messaging, promotional offers, and newsletters that help
                      maintain relationships and drive conversions.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Analytics and Reporting</h5>
                    <p className="mb-0">
                      Utilizing analytics tools to track and measure the
                      performance of digital marketing efforts. This data-driven
                      approach helps businesses understand user behavior, assess
                      campaign effectiveness, and make informed decisions for
                      future strategies.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <TbReportAnalytics />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
            <img className="width-full mt-5" src={service2} alt="service" />
          </Col>
          <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
            <h2 className="mt-5 pt-4">Why Choose Us for Digital Marketing?</h2>
            <ul className="list-inline list-group ul-list-about mt-4">
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Data-driven strategies tailored to your target audience</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Expertise in SEO, social media, and content marketing</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Proven track record of increasing brand visibility</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Comprehensive analytics for measuring success</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Creative campaigns that resonate with your audience</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Common>
  );
};

export default DigitalMarketing;
