import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollTop";

import Home from "./pages/home/Home";
import Mission from "./pages/home/Mission";
import Vision from "./pages/home/Vision";
import Portfolio from "./pages/portfolio/Portfolio";
import Blog from "./pages/blog/Blog";
import Blogdetails from "./pages/blog/Blogdetails";
import Aboutus from "./pages/about/Aboutus";
import Team from "./pages/about/Team";
import Contact from "./pages/Contact";
import FreeQuote from "./pages/FreeQuote";

import SoftwareAndWebDevelopment from "./pages/servicesv2/SoftwareAndWebDevelopment";
import MobileAppDevelopment from "./pages/servicesv2/MobileAppDevelopment";
import QualityAssurance from "./pages/servicesv2/QualityAssurance";
import DevOps from "./pages/servicesv2/DevOps";
import HireAndOffshore from "./pages/servicesv2/HireAndOffshore";
import StaffAugmentation from "./pages/servicesv2/StaffAugmentation";
import DigitalMarketing from "./pages/servicesv2/DigitalMarketing";
import ArtificialIntelligence from "./pages/servicesv2/ArtificialIntelligence";
import BigData from "./pages/servicesv2/BigData";

import TermsAndConditions from "./pages/termsandconditions/TermsAndConditions";
import Policy from "./pages/policy/Policy";

const Routing = () => {
  return (
    <HashRouter hashType="noslash">
      <ScrollToTop />
      <Routes>
        <Route>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />

          <Route path="contact" element={<Contact />} />
          <Route path="free-quote" element={<FreeQuote />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog-details" element={<Blogdetails />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="about-us" element={<Aboutus />} />
          <Route path="team" element={<Team />} />
          <Route path="vision" element={<Vision />} />
          <Route path="mission" element={<Mission />} />

          {/* services v2 */}
          <Route
            path="software-web-development"
            element={<SoftwareAndWebDevelopment />}
          />
          <Route
            path="mobile-app-development"
            element={<MobileAppDevelopment />}
          />
          <Route path="quality-assurance" element={<QualityAssurance />} />
          <Route path="devops" element={<DevOps />} />
          <Route path="hire-offshore" element={<HireAndOffshore />} />
          <Route path="staff-augmentation" element={<StaffAugmentation />} />
          <Route path="digital-marketing" element={<DigitalMarketing />} />
          <Route path="ai" element={<ArtificialIntelligence />} />
          <Route path="big-data" element={<BigData />} />
          {/* services v2 */}

          <Route path="terms-conditions" element={<TermsAndConditions />} />
          <Route path="privacy-policy" element={<Policy />} />
        </Route>
      </Routes>
    </HashRouter>
  );
};

export default Routing;
