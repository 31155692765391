import React from "react";
import { NavLink } from "react-router-dom";
import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";

import about1 from "../../assets/images/view/about1.svg";
import about2 from "../../assets/images/view/about2.svg";
import mission from "../../assets/images/view/mission.svg";
import vision from "../../assets/images/view/vision.svg";

import ofcImg1 from "../../assets/images/office/1.webp";
import ofcImg2 from "../../assets/images/office/2.webp";
import ofcImg3 from "../../assets/images/office/3.webp";
import ofcImg4 from "../../assets/images/office/4.webp";
import ofcImg5 from "../../assets/images/office/5.webp";
import ofcImg6 from "../../assets/images/office/6.webp";

const Aboutus = () => {
  return (
    <Common>
      <section className="all-sub-banner portfolio-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow"></div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="port-sec mt-173">
        <div className="all-company-feature pt-2">
          <Container>
            <Row>
              <Col md={6} className="mb-4" data-aos="fade-right">
                <h2 className="mt-4">Who We Are </h2>
                <p>
                  At Automation Services Ltd, we believe in the power of
                  technology to transform businesses. As a software company
                  specializing in IT solutions and services, we help businesses
                  of all sizes leverage technology to improve their operations
                  and increase their efficiency.
                  <br />
                  Since our inception, we have been committed to providing our
                  clients with the best possible software solutions and
                  services. We understand that every business is unique, and we
                  work closely with our clients to understand their specific
                  needs and requirements. This allows us to develop customized
                  software solutions that are tailored to the unique needs of
                  each business.
                </p>
              </Col>
              <Col md={6} className="mb-4" data-aos="fade-left">
                <img className="abouimg mt-4" src={about1} alt="about" />
              </Col>
            </Row>
          </Container>
        </div>

        <section className="all-company-feature pt-3">
          <Container>
            <Row>
              <Col md={6} className="mt-4 mb-4" data-aos="fade-right">
                <div className="company-block-feature-two">
                  {" "}
                  <img src={about2} alt="about" />{" "}
                </div>
              </Col>
              <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
                <h2>Engineering Excellence</h2>
                <p>
                  With our 10+ years of experience in integrating next-gen
                  technologies into businesses, we have created various
                  applications that are performing well and helping businesses
                  one-up their competition.
                </p>
                <p>
                  Founded in 2015, our offshore IT services are globally
                  recognized for calibrating solutions & innovative structuring.
                </p>

                <h2 className="mt-5">Our Infrastructure</h2>
                <p>
                  Based in the heart of USA, Bangladesh, Automation Services Ltd
                  has state-of-the-art infrastructural facilities powered with
                  latest hardware and software. Our agile infrastructure coupled
                  with cloud computing make it perfect for outsourcing. We have
                  separate labs for different technologies such as: Web
                  Development, Mobile App Development, Research & Development,
                  Designing, Testing, and other support departments for Admin,
                  HR, Finance, etc. This advanced infrastructure unit helps us
                  to deliver only the best-in-class services according to varied
                  business needs. Since our inception, we have invested in
                  organic growth; building on our engineering capabilities,
                  organizational processes, and culture required to deliver a
                  truly collaborative ecosystem for solving technology
                  challenges. At the core of Automation Services Ltd lies a
                  culture rooted in innovation, learning, and a result-oriented
                  mindset.
                </p>
                <Row>
                  <div
                    className="company-slide mission-vission mt-4 abt-block"
                    data-aos="flip-left"
                  >
                    <Row>
                      <Col md={3}>
                        <div className="slider-icon">
                          {" "}
                          <img src={vision} alt="vision" />{" "}
                        </div>
                      </Col>
                      <Col md={9} className="ps-0">
                        <h5 className="p-0">Our Vision</h5>
                        <p className="mb-0">
                          {" "}
                          Across the globe, the Enterprise ecosystem is
                          evolving. This has created a new scenario for
                          businesses operating in the market…{" "}
                        </p>
                        <NavLink exact to="/vision">
                          Read more...
                        </NavLink>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="company-slide mission-vission mt-4"
                    data-aos="flip-right"
                  >
                    <Row>
                      <Col md={9} className="pe-0 text-end">
                        <h5 className="p-0">Our Mission</h5>
                        <p className="mb-0">
                          {" "}
                          SAcross the globe, the Enterprise ecosystem is
                          evolving. This has created a new scenario for
                          businesses operating in the market. You either have
                          robus…{" "}
                        </p>
                        <NavLink exact to="/mission">
                          Read more...
                        </NavLink>
                      </Col>
                      <Col md={3}>
                        <div className="slider-icon">
                          {" "}
                          <img src={mission} alt="mission" />{" "}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </Col>

              <Col md={12} className="text-center pt-5 mt-5">
                <h2>A Glimpse Of Our Offices</h2>
                <p className="text-center mb-5">
                  Automation Services Ltd has two offices that are located in
                  the Bangladesh and United States.
                  <br />
                  Here’s a collection of photographs from our offices
                </p>
              </Col>

              <Col md={4} data-aos="flip-up">
                <div className="portfolio-com">
                  {" "}
                  <img src={ofcImg1} alt="office" />{" "}
                </div>
              </Col>
              <Col md={4} data-aos="flip-up">
                <div className="portfolio-com">
                  {" "}
                  <img src={ofcImg2} alt="office" />{" "}
                </div>
              </Col>
              <Col md={4} data-aos="flip-up">
                <div className="portfolio-com">
                  {" "}
                  <img src={ofcImg3} alt="office" />{" "}
                </div>
              </Col>
              <Col md={4} data-aos="flip-up">
                <div className="portfolio-com">
                  {" "}
                  <img src={ofcImg4} alt="office" />{" "}
                </div>
              </Col>
              <Col md={4} data-aos="flip-up">
                <div className="portfolio-com">
                  {" "}
                  <img src={ofcImg5} alt="office" />{" "}
                </div>
              </Col>
              <Col md={4} data-aos="flip-up">
                <div className="portfolio-com">
                  {" "}
                  <img src={ofcImg6} alt="office" />{" "}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </section>
    </Common>
  );
};

export default Aboutus;
