import React from "react";

import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";

import { FaCheckCircle, FaHandPointRight, FaStarOfLife } from "react-icons/fa";
import { MdOutlineDataset, MdOutlineStorage } from "react-icons/md";
import { BsDatabaseGear } from "react-icons/bs";
import { RiRobot2Line } from "react-icons/ri";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";

import service1 from "../../assets/images/view/service1.svg";
import service2 from "../../assets/images/view/service2.svg";

import ContactForm from "../../components/ContactForm";

const BigData = () => {
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>Big Data</strong>
                </h1>
                <p className="text-white p20 mb-4">
                  In an era where data drives decisions, Automation Services
                  Limited (ASL) empowers businesses to leverage big data for
                  strategic advantage. Our comprehensive big data solutions help
                  you collect, analyze, and visualize vast amounts of
                  information, transforming raw data into actionable insights.
                  With expertise in data architecture, analytics, and machine
                  learning, we tailor our services to fit your unique
                  requirements. By implementing effective big data strategies,
                  you can enhance customer experiences, optimize operations, and
                  make informed decisions that propel your business forward.
                  Partner with ASL to unlock the potential of big data today.
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Unlock Data Potential</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Advanced Analytics Solutions</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Real-Time Data Processing</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Scalable Infrastructure</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Data Visualization</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Enhanced Business Strategies</label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>

      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
              <h2>Our Big Data Services</h2>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <BsDatabaseGear />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Data Collection and Ingestion</h5>
                    <p className="mb-0">
                      Implementing systems and processes to gather and ingest
                      large volumes of structured and unstructured data from
                      various sources. This includes using tools and
                      technologies to efficiently capture data from databases,
                      APIs, IoT devices, and social media.
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Data Storage Solutions</h5>
                    <p className="mb-0">
                      Providing scalable storage solutions that can accommodate
                      vast amounts of data while ensuring security and
                      accessibility. This includes utilizing technologies like
                      data lakes, cloud storage, and distributed file systems to
                      manage data effectively.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <MdOutlineStorage />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={service1} alt="service" />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <TbDeviceDesktopAnalytics />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Data Processing and Analytics</h5>
                    <p className="mb-0 pb-3">
                      Employing advanced analytics techniques to process and
                      analyze big data. This includes using frameworks like
                      Apache Hadoop and Spark to perform batch processing and
                      real-time analytics, allowing businesses to derive
                      actionable insights from their data.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0"> Data Visualization</h5>
                    <p className="mb-0">
                      Creating interactive dashboards and visualizations to
                      represent complex data insights clearly and intuitively.
                      This helps stakeholders easily understand trends,
                      patterns, and anomalies, facilitating informed
                      decision-making.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <MdOutlineDataset />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <RiRobot2Line />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Machine Learning and AI Integration</h5>
                    <p className="mb-0 pb-4">
                      Integrating machine learning algorithms with big data
                      analytics to uncover deeper insights and automate
                      decision-making processes. This allows organizations to
                      leverage predictive analytics, customer segmentation, and
                      personalization strategies effectively.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Data Governance and Compliance</h5>
                    <p className="mb-0">
                      Establishing data governance frameworks to ensure data
                      quality, privacy, and compliance with regulations. This
                      includes implementing policies and practices for data
                      management, security, and ethical use, ensuring that
                      organizations handle data responsibly.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaStarOfLife />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
            <img className="width-full mt-5" src={service2} alt="service" />
          </Col>
          <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
            <h2 className="mt-5 pt-4">Why Choose Us for Big Data?</h2>
            <ul className="list-inline list-group ul-list-about mt-4">
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Advanced tools for data collection and processing</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Scalable storage solutions for large datasets</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Expertise in analytics and visualization techniques</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Integration of AI for predictive insights</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Commitment to data governance and compliance</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Common>
  );
};

export default BigData;
