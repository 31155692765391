import React from "react";

import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";

import {
  FaCheckCircle,
  FaHandPointRight,
  FaSortAmountUp,
} from "react-icons/fa";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { BsGraphDownArrow } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { MdDesignServices } from "react-icons/md";
import { FaHandHoldingDollar } from "react-icons/fa6";

import service1 from "../../assets/images/view/service1.svg";
import service2 from "../../assets/images/view/service2.svg";

import ContactForm from "../../components/ContactForm";

const StaffAugmentation = () => {
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>Staff Augmentation</strong>
                </h1>
                <p className="text-white p20 mb-4">
                  In the dynamic world of business, finding the right talent
                  quickly is essential. Automation Services Limited (ASL) offers
                  staff augmentation services designed to help you meet your
                  project demands efficiently. Our flexible approach allows you
                  to seamlessly integrate skilled professionals into your team,
                  whether for short-term projects or long-term engagements. We
                  provide access to experts across various domains, enabling you
                  to scale your workforce as needed. With ASL, you can enhance
                  productivity, reduce hiring costs, and focus on your core
                  business objectives without missing a beat.
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Flexible Staffing Solutions</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Access to Specialized Skills</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Cost-Effective Resource Management</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Faster Time to Market</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Seamless Integration</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Focus on Core Competencies</label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>

      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
              <h2>Our Staff Augmentation Services</h2>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <AiOutlineFundProjectionScreen />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Enhanced Project Focus</h5>
                    <p className="mb-0">
                      Allowing organizations to maintain focus on their core
                      business objectives by bringing in external expertise to
                      handle specific projects or tasks. This ensures that
                      internal teams can concentrate on strategic initiatives
                      while outsourced staff manage project execution.
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Reduced Risk</h5>
                    <p className="mb-0">
                      Minimizing the risks associated with workforce
                      fluctuations and project demands. Staff augmentation
                      provides companies with the ability to adjust resources
                      quickly without the financial burden of layoffs or
                      overstaffing.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <BsGraphDownArrow />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={service1} alt="service" />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <MdDesignServices />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Access to Specialized Skills</h5>
                    <p className="mb-0 pb-3">
                      Providing businesses with immediate access to specialized
                      skills and expertise that may be lacking in-house. This
                      ensures that teams can quickly fill skill gaps and
                      leverage specific knowledge for project success.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Flexible Staffing Solutions</h5>
                    <p className="mb-0">
                      Offering flexible staffing arrangements that allow
                      companies to scale their workforce up or down based on
                      project demands. This flexibility helps organizations
                      respond to changing requirements without the long-term
                      commitments associated with permanent hires.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <BiSupport />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSortAmountUp />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Faster Time to Market</h5>
                    <p className="mb-0 pb-4">
                      Enabling quicker project delivery by integrating skilled
                      professionals into existing teams. This acceleration helps
                      companies meet tight deadlines and stay competitive by
                      bringing products and services to market more rapidly.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Cost-Effectiveness</h5>
                    <p className="mb-0">
                      Reducing hiring costs associated with recruitment,
                      training, and onboarding by using staff augmentation. This
                      approach allows businesses to optimize their budgets by
                      hiring only the necessary talent for specific projects.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaHandHoldingDollar />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
            <img className="width-full mt-5" src={service2} alt="service" />
          </Col>
          <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
            <h2 className="mt-5 pt-4">Why Choose Us for Staff Augmentation?</h2>
            <ul className="list-inline list-group ul-list-about mt-4">
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Immediate access to specialized skills and expertise</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Scalable staffing solutions for dynamic project needs</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Reduced time to market with fast integration</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Cost-effective model with minimal overhead</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Enhanced focus on core business objectives</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Common>
  );
};

export default StaffAugmentation;
