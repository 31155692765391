import React from "react";

function PolicyEn() {
  return (
    <div className="my-4">
      <div>
        <h2>Privacy Policy for Automation Services Ltd</h2>
        <p>
          <span className="font-weight-bold">Last updated:</span> February 10,
          2025
        </p>
      </div>

      <hr className="opacity-25" />

      <p>
        At <span className="font-weight-bold">Automation Services Ltd</span>, we
        value your privacy. This Privacy Policy outlines how we collect, use,
        store, and protect your personal information when you visit our website{" "}
        <a href="https://www.automationservicesbd.com" target="_blank">
          Automation Services Ltd
        </a>{" "}
        and use our services.
      </p>

      <div>
        <h3>Information We Collect</h3>
        <p>We may collect the following types of information:</p>
        <ul>
          <li>
            <span className="font-weight-bold">Personal Information:</span>{" "}
            Name, email address, phone number, billing information, etc., that
            you provide when you register or interact with our services.
          </li>
          <li>
            <span className="font-weight-bold">Usage Information:</span>{" "}
            Information about how you access and interact with our website,
            including IP addresses, browser type, device information, and usage
            patterns.
          </li>
          <li>
            <span className="font-weight-bold">
              Cookies and Tracking Technologies:
            </span>{" "}
            We use cookies and similar technologies to collect information about
            your preferences and activities on our website.
          </li>
        </ul>
      </div>

      <div>
        <h3>How We Use Your Informationtion</h3>
        <p>We may use the collected information for the following purposes:</p>
        <ul>
          <li>To provide and improve our services.</li>
          <li>
            To communicate with you, respond to inquiries, and send updates
            about our services.
          </li>
          <li>To personalize your experience on our website.</li>
          <li>
            To comply with legal obligations and enforce our terms and
            conditions.
          </li>
        </ul>
      </div>

      <div>
        <h3>Data Security</h3>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, alteration, or destruction. However, please note
          that no data transmission or storage method is 100% secure.
        </p>
      </div>

      <div>
        <h3>Sharing Your Information</h3>
        <p>
          We may share your information with third-party service providers that
          help us operate our business, such as payment processors and hosting
          providers. We will never sell or rent your personal information to
          third parties.
        </p>
      </div>

      <div>
        <h3>Your Rights</h3>
        <p>You have the right to:</p>
        <ul>
          <li>Access, update, or delete your personal information.</li>
          <li>
            Withdraw consent for the collection and use of your data at any
            time.
          </li>
          <li>
            Object to the processing of your personal data under certain
            circumstances.
          </li>
        </ul>
      </div>

      <div>
        <h3>Third-Party Links</h3>
        <p>
          Our website may contain links to third-party websites. We are not
          responsible for the privacy practices of these websites and encourage
          you to review their privacy policies.
        </p>
      </div>

      <div>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page with an updated "Effective Date." Please check
          this page periodically for any updates.
        </p>
      </div>

      <div>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>
        <ul>
          <li>Email: info@automationservicesbd.com</li>
          <li>Phone: 880 1847 364080</li>
        </ul>
      </div>
    </div>
  );
}

export default PolicyEn;
