// eslint-disable-next-line
import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Common from "../../layouts/Common";
import Carousel from "react-bootstrap/Carousel";
import Form from "react-bootstrap/Form";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { FaArrowRight, FaArrowAltCircleRight } from "react-icons/fa";

import swdIcon from "../../assets/images/service-icon/swd.svg";
import devIcon from "../../assets/images/service-icon/dev.svg";
import hoIcon from "../../assets/images/service-icon/ho.svg";
import dmIcon from "../../assets/images/service-icon/dm.svg";
import aiIcon from "../../assets/images/service-icon/ai.svg";
import bdIcon from "../../assets/images/service-icon/bd.svg";

import angular from "../../assets/images/technology/angular.svg";
import reacjs from "../../assets/images/technology/reacjs.svg";
import python from "../../assets/images/technology/python.svg";
import dotnet from "../../assets/images/technology/dotnet.svg";
import vue from "../../assets/images/technology/vue.svg";
import php from "../../assets/images/technology/php.svg";
import node from "../../assets/images/technology/node.svg";
import laravel from "../../assets/images/technology/laravel.svg";
import magento from "../../assets/images/technology/magento.svg";
import wp from "../../assets/images/technology/wp.svg";
import codignitor from "../../assets/images/technology/codignitor.svg";
import ios from "../../assets/images/technology/ios.svg";
import android from "../../assets/images/technology/android.svg";
import bootstrap from "../../assets/images/technology/bootstrap.svg";
import ember from "../../assets/images/technology/ember.svg";

import client1 from "../../assets/images/clients/client_1.svg";
import client2 from "../../assets/images/clients/client_2.svg";
import client3 from "../../assets/images/clients/client_3.svg";
import client4 from "../../assets/images/clients/client_4.svg";
import client5 from "../../assets/images/clients/client_5.svg";
import client6 from "../../assets/images/clients/client_6.svg";
import client7 from "../../assets/images/clients/client_7.svg";
import client8 from "../../assets/images/clients/client_8.svg";
import client9 from "../../assets/images/clients/client_9.svg";
import client10 from "../../assets/images/clients/client_10.svg";
import client11 from "../../assets/images/clients/client_11.svg";
import client12 from "../../assets/images/clients/client_12.svg";
import client13 from "../../assets/images/clients/client_13.svg";
import client14 from "../../assets/images/clients/client_14.svg";
import client15 from "../../assets/images/clients/client_15.svg";
import client16 from "../../assets/images/clients/client_16.svg";
import client17 from "../../assets/images/clients/client_17.svg";
import client18 from "../../assets/images/clients/client_18.svg";
import client19 from "../../assets/images/clients/client_19.svg";
import client20 from "../../assets/images/clients/client_20.svg";
import client21 from "../../assets/images/clients/client_21.svg";
import client22 from "../../assets/images/clients/client_22.svg";
import client23 from "../../assets/images/clients/client_23.svg";
import client24 from "../../assets/images/clients/client_24.svg";
import client25 from "../../assets/images/clients/client_25.svg";
import client26 from "../../assets/images/clients/client_26.svg";
import client27 from "../../assets/images/clients/client_27.svg";
import client28 from "../../assets/images/clients/client_28.svg";
import client29 from "../../assets/images/clients/client_29.svg";
import client30 from "../../assets/images/clients/client_30.svg";
import client31 from "../../assets/images/clients/client_31.svg";
import client32 from "../../assets/images/clients/client_32.svg";

import partner1 from "../../assets/images/partners/microsoft.svg";
import partner2 from "../../assets/images/partners/googlepartner.svg";
import partner3 from "../../assets/images/partners/awslogo.svg";
import partner4 from "../../assets/images/partners/dreamapp.svg";
import partner5 from "../../assets/images/partners/kaspersky.svg";
import partner6 from "../../assets/images/partners/oracle.svg";
import partner7 from "../../assets/images/partners/trend.svg";
import partner8 from "../../assets/images/partners/acronis.svg";
import partner9 from "../../assets/images/partners/eset.svg";
import partner10 from "../../assets/images/partners/odoo.svg";

import airplane from "../../assets/images/industry/airplane.png";
import bank from "../../assets/images/industry/bank.png";
import ecommerce from "../../assets/images/industry/ecommerce.png";
import manufacture from "../../assets/images/industry/manufacture.png";
import healthcare from "../../assets/images/industry/healthcare.png";
import telecommunication from "../../assets/images/industry/telecommunication.png";
import education from "../../assets/images/industry/education.png";
import energy from "../../assets/images/industry/energy.png";
import govt from "../../assets/images/industry/govt.png";
import tourism from "../../assets/images/industry/tourism.png";

import about from "../../assets/images/view/about.svg";
import vision from "../../assets/images/view/vision.svg";
import mission from "../../assets/images/view/mission.svg";
import missionvision from "../../assets/images/view/missionvision.svg";
import client from "../../assets/images/view/client.svg";
import avater from "../../assets/images/view/avater.svg";

import partner from "../../assets/images/info/partner.svg";
import experience from "../../assets/images/info/experience.svg";
import countries from "../../assets/images/info/countries.svg";
import brain from "../../assets/images/info/brain.svg";
import project from "../../assets/images/info/project.svg";

import work1 from "../../assets/images/portfolio/web/erp.webp";
import work2 from "../../assets/images/portfolio/web/fdm.webp";
import work3 from "../../assets/images/portfolio/web/icx.webp";
import work4 from "../../assets/images/portfolio/web/his.webp";
import work5 from "../../assets/images/portfolio/web/screenwriting2.webp";
import work6 from "../../assets/images/portfolio/app/bitcoin.webp";
import work7 from "../../assets/images/portfolio/app/delivery.webp";
import work8 from "../../assets/images/portfolio/app/fdmapp.webp";
import work9 from "../../assets/images/portfolio/app/prepaidmeter.webp";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import FreeQuote from "../FreeQuote";
import Modal from "react-bootstrap/Modal";

import { toast } from "react-hot-toast";

const Home = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const baseURL = process.env.REACT_APP_BASE_URL;

  const clients = [
    {
      id: 1,
      imgUrl: client1,
    },
    {
      id: 2,
      imgUrl: client2,
    },
    {
      id: 3,
      imgUrl: client3,
    },
    {
      id: 4,
      imgUrl: client4,
    },
    {
      id: 5,
      imgUrl: client5,
    },
    {
      id: 6,
      imgUrl: client6,
    },
    {
      id: 7,
      imgUrl: client7,
    },
    {
      id: 8,
      imgUrl: client8,
    },
    {
      id: 9,
      imgUrl: client9,
    },
    {
      id: 10,
      imgUrl: client10,
    },
    {
      id: 11,
      imgUrl: client11,
    },
    {
      id: 12,
      imgUrl: client12,
    },
    {
      id: 13,
      imgUrl: client13,
    },
    {
      id: 14,
      imgUrl: client14,
    },
    {
      id: 15,
      imgUrl: client15,
    },
    {
      id: 16,
      imgUrl: client16,
    },
    {
      id: 17,
      imgUrl: client17,
    },
    {
      id: 18,
      imgUrl: client18,
    },
    {
      id: 19,
      imgUrl: client19,
    },
    {
      id: 20,
      imgUrl: client20,
    },
    {
      id: 21,
      imgUrl: client21,
    },
    {
      id: 22,
      imgUrl: client22,
    },
    {
      id: 23,
      imgUrl: client23,
    },
    {
      id: 24,
      imgUrl: client24,
    },
    {
      id: 25,
      imgUrl: client25,
    },
    {
      id: 26,
      imgUrl: client26,
    },
    {
      id: 27,
      imgUrl: client27,
    },
    {
      id: 28,
      imgUrl: client28,
    },
    {
      id: 29,
      imgUrl: client29,
    },
    {
      id: 30,
      imgUrl: client30,
    },
    {
      id: 31,
      imgUrl: client31,
    },
    {
      id: 32,
      imgUrl: client32,
    },
  ];

  const partners = [
    { id: 1, imgUrl: partner1 },
    { id: 2, imgUrl: partner2 },
    { id: 3, imgUrl: partner3 },
    { id: 4, imgUrl: partner4 },
    { id: 5, imgUrl: partner5 },
    { id: 6, imgUrl: partner6 },
    { id: 7, imgUrl: partner7 },
    { id: 8, imgUrl: partner8 },
    { id: 9, imgUrl: partner9 },
    { id: 10, imgUrl: partner10 },
  ];

  const recentWorks = [
    {
      id: 1,
      imgUrl: work1,
    },
    {
      id: 2,
      imgUrl: work2,
    },
    {
      id: 3,
      imgUrl: work3,
    },
    {
      id: 4,
      imgUrl: work4,
    },
    {
      id: 5,
      imgUrl: work5,
    },
    {
      id: 6,
      imgUrl: work6,
    },
    {
      id: 7,
      imgUrl: work7,
    },
    {
      id: 8,
      imgUrl: work8,
    },
    {
      id: 9,
      imgUrl: work9,
    },
  ];

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const optionsN = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    let formdata = new FormData();
    formdata.append("date", inputs?.scheduleDate ?? "");
    formdata.append("time", inputs?.scheduleTime ?? "");
    formdata.append("name", inputs?.name ?? "");
    formdata.append("email", inputs?.email ?? "");
    formdata.append("phone", inputs?.phone ?? "");
    formdata.append("message", inputs?.comment ?? "");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };

    try {
      const res = await fetch(
        `${baseURL}/api/schedule/meeting/form`,
        requestOptions
      ).then((response) => response.json());

      if (res?.status && res?.code === 200) {
        setInputs({});
        return toast.success(res?.message);
      } else {
        return toast.error("Message not sent!");
      }
    } catch (error) {
      return toast.error("Message not sent!");
    }
  };

  return (
    <Common>
      <section className="banner-section">
        <Container>
          <Row>
            <Col md={12}>
              <div className="slider-banner slide-arrow">
                {/* fade */}
                <Carousel>
                  <Carousel.Item>
                    <h1 className="text-white">
                      <strong>IT Consulting</strong>
                    </h1>
                    <p className="text-white p20">
                      As a leading IT consulting firm, we help businesses to
                      reinvent and <br />
                      excel by establishing complete relianceon information
                      technology and <br />
                      offering high quality technology consulting services.{" "}
                    </p>

                    <NavLink exact to="/about-us">
                      <Button variant="primary btn-custom mt-3 btn-big">
                        Read More
                        <FaArrowRight />
                      </Button>
                    </NavLink>
                  </Carousel.Item>

                  <Carousel.Item>
                    <h1 className="text-white">
                      <strong>Hire Dedicated Developers</strong>
                    </h1>
                    <p className="text-white p20">
                      Automation Services Ltd is a leading offshore development
                      partner with offices in <br />
                      Bangladesh and USA. Our deep understanding about the
                      offshore
                      <br />
                      software outsourcing makes us a unique solution provider.
                    </p>
                    <NavLink exact to="/about-us">
                      <Button variant="primary btn-custom mt-3 btn-big">
                        Read More
                        <FaArrowRight />
                      </Button>
                    </NavLink>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center pt-3">
              <h4 className="mt-5">SERVICES</h4>
              <h2>Our Services</h2>
            </Col>
          </Row>

          <Row>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={swdIcon} alt="Software & Web Development" />
                </span>
                <h5 className="text-center mt-5">Software & Web Development</h5>
                <p>
                  Crafting cutting-edge solutions that elevate your digital
                  presence and drive growth.
                </p>
                <ul className="ul-feature">
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Web Development</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>App Development</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>UI/UX</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>ERP Solution</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Quality Assurance</p>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={devIcon} alt="DevOps" />
                </span>
                <h5 className="text-center mt-5">DevOps</h5>
                <p>
                  Streamlining your development processes for faster, more
                  reliable software delivery.
                </p>
                <ul className="ul-feature">
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Containerization</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Infrastructure as Code</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>CI/CD</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Monitoring and Logging</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Cloud Service</p>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={hoIcon} alt="Hire & Offshore" />
                </span>
                <h5 className="text-center mt-5">Hire & Offshore</h5>
                <p>
                  Enhancing your team with top tier talent for seamless
                  scalability and efficiency.
                </p>
                <ul className="ul-feature">
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Offshore Outsourcing</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Staff Augmentation</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>RPO Services</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>BPO Solutions</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>KPO Services</p>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="320"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={dmIcon} alt="Digital Marketing" />
                </span>
                <h5 className="text-center mt-5">Digital Marketing</h5>
                <p>
                  Strategically amplifying your brands reach and impact in the
                  digital landscape.
                </p>
                <ul className="ul-feature">
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>SEO</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Email Marketing</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Content Marketing</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>PPC Advertising</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Social Media Marketing</p>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="320"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={aiIcon} alt="Artificial Intelligence" />
                </span>
                <h5 className="text-center mt-5">Artificial Intelligence</h5>
                <p>
                  Leveraging Al to unlock new insights and possibilities for
                  your business.
                </p>
                <ul className="ul-feature">
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Machine Learning</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Computer Vision</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>LLM</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>AI in Healthcare</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>NLP</p>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="320"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={bdIcon} alt="Big Data" />
                </span>
                <h5 className="text-center mt-5">Big Data</h5>
                <p>
                  Hamessing the power of data to drive informed decision-making
                  and unlock actionable insights.
                </p>
                <ul className="ul-feature">
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Data Storage</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Data Processing</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Data Integration</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Data Analytics</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Data Governance & Security</p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="text-center mt-4">
              <Button
                variant="primary btn-custom mt-3 btn-big w260"
                onClick={handleShow}
              >
                Get a Free Quote
                <FaArrowRight className="ms-5" />
              </Button>
            </Col>
          </Row>
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
          <Modal.Body>
            <FreeQuote />
          </Modal.Body>
        </Modal>
      </section>

      <section className="all-company-feature frame-bg mt-4">
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <h4>TECHNOLOGIES</h4>
              <h2>Our Technologies</h2>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <ul className="ul-tech-list">
                <li data-aos="fade-right">
                  <span>
                    <img src={angular} alt="angular" />
                  </span>
                  <p>Angularjs</p>
                </li>

                <li data-aos="fade-right">
                  <span>
                    <img src={reacjs} alt="reacjs" />
                  </span>
                  <p>Reactjs</p>
                </li>

                <li data-aos="fade-right">
                  <span>
                    <img src={python} alt="python" />
                  </span>
                  <p>Python</p>
                </li>

                <li data-aos="fade-right">
                  <span>
                    <img src={dotnet} alt="dotnet" />
                  </span>
                  <p>ASP.NET MVC</p>
                </li>

                <li data-aos="fade-right" className="border-end-0">
                  <span>
                    <img src={vue} alt="vuejs" />
                  </span>
                  <p>Vue JS</p>
                </li>

                <li data-aos="fade-left" className="border-bottom-0">
                  <span>
                    <img src={php} alt="php" />
                  </span>
                  <p>PHP</p>
                </li>

                <li data-aos="fade-left" className="border-bottom-0">
                  <span>
                    <img src={node} alt="nodejs" />
                  </span>
                  <p>Node.js</p>
                </li>

                <li data-aos="fade-left" className="border-bottom-0">
                  <span>
                    <img src={laravel} alt="laravel" />
                  </span>
                  <p>Laravel</p>
                </li>

                <li data-aos="fade-left" className="border-bottom-0">
                  <span>
                    <img src={magento} alt="magento" />
                  </span>
                  <p>Magento</p>
                </li>

                <li
                  data-aos="fade-left"
                  className="border-bottom-0 border-end-0"
                >
                  <span>
                    <img src={wp} alt="wordpress" />
                  </span>
                  <p>Wordpress</p>
                </li>

                <li
                  data-aos="fade-right"
                  className="border-bottom-0 border-top-do"
                >
                  <span>
                    <img src={codignitor} alt="codignitor" />
                  </span>
                  <p>Codeignitor</p>
                </li>

                <li
                  data-aos="fade-right"
                  className="border-bottom-0 border-top-do"
                >
                  <span>
                    <img src={ios} alt="ios" />
                  </span>
                  <p>iOS</p>
                </li>

                <li
                  data-aos="fade-right"
                  className="border-bottom-0 border-top-do"
                >
                  <span>
                    <img src={android} alt="android" />
                  </span>
                  <p>Android</p>
                </li>

                <li
                  data-aos="fade-right"
                  className="border-bottom-0 border-top-do"
                >
                  <span>
                    <img src={bootstrap} alt="bootstrap" />
                  </span>
                  <p>Bootstrap</p>
                </li>

                <li
                  data-aos="fade-right"
                  className="border-bottom-0 border-end-0 border-top-do"
                >
                  <span>
                    <img src={ember} alt="ember" />
                  </span>
                  <p>Ember</p>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature my-4 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mb-4">
              <h4>CLIENTS</h4>
              <h2>We Are Trusted by</h2>
            </Col>
          </Row>

          <Row>
            <OwlCarousel
              className="owl-theme slider-custom custom-industry slide-img2"
              {...optionsN}
              loop
              margin={20}
              nav
            >
              {clients?.map((client) => (
                <div class="item" key={client?.id}>
                  <div className="company-slide2">
                    <div className="slider-icon2">
                      {" "}
                      <img src={client?.imgUrl} alt="client" />{" "}
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mt-4">
        <Container className="bg-gray">
          <Row>
            <Col md={6} className="schedu-bg p-0" data-aos="fade-down-right">
              <div className="block-schedue">
                <h6 className="text-white">SCHEDULE MEETING</h6>
                <h5 className="text-white mt-4">
                  Schedule A Custom 20 Min Consultation
                </h5>
                <p className="text-white mt-1">
                  Contact us today to schedule a free, 20-minute call to learn
                  how Automation Services Ltd can help you revolutionize the way
                  your company conducts business.
                </p>
              </div>
            </Col>

            <Col md={6} className="form-area" data-aos="fade-down-left">
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3 me-0 sch-block">
                  <h3 className="h3-schedule mb-4">Schedule Meeting</h3>
                  <Form.Group as={Col} className="ps-0">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="scheduleDate"
                      value={inputs.scheduleDate || ""}
                      onChange={handleChange}
                      placeholder="MM/DD/YYYY"
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="pe-0 spaceres">
                    <Form.Label>Time</Form.Label>
                    <Form.Control
                      type="time"
                      name="scheduleTime"
                      value={inputs.scheduleTime || ""}
                      onChange={handleChange}
                      placeholder="HH:MM AM/PM"
                      required
                    />
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={inputs.name || ""}
                    onChange={handleChange}
                    placeholder=""
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={inputs.email || ""}
                    onChange={handleChange}
                    placeholder=""
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Contact number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={inputs.phone || ""}
                    onChange={handleChange}
                    placeholder=""
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="comment"
                    value={inputs.comment || ""}
                    onChange={handleChange}
                    className="textform"
                    rows={3}
                    required
                  />
                </Form.Group>
                <Col md={12} className="text-center mt-4">
                  <Button
                    variant="primary btn-custom mt-3 btn-big"
                    type="submit"
                  >
                    Submit
                    <FaArrowRight className="ms-5" />
                  </Button>
                </Col>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mt-4 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mb-4">
              <h4>INDUSTRY EXPERTISE </h4>
              <h2>Our Industry Experience</h2>
            </Col>
          </Row>

          <Row>
            <OwlCarousel
              className="owl-theme slider-custom custom-industry"
              {...options}
              loop
              margin={20}
              nav
            >
              <div class="item">
                <h4>
                  <div className="company-slide bg1">
                    <div className="slider-icon">
                      {" "}
                      <img src={manufacture} alt="Manufacturing" />{" "}
                    </div>
                    <h5>Manufacturing</h5>
                  </div>
                </h4>
              </div>

              <div class="item">
                <div className="company-slide bg2">
                  <div className="slider-icon">
                    {" "}
                    <img
                      src={airplane}
                      alt="Transportation and Logistics"
                    />{" "}
                  </div>
                  <h5>Transport and Logistics</h5>
                </div>
              </div>

              <div class="item">
                <div className="company-slide bg1">
                  <div className="slider-icon">
                    {" "}
                    <img src={healthcare} alt="Healthcare" />{" "}
                  </div>
                  <h5>Healthcare</h5>
                </div>
              </div>

              <div class="item">
                <div className="company-slide bg2">
                  <div className="slider-icon">
                    {" "}
                    <img src={education} alt="aviation" />{" "}
                  </div>
                  <h5>Education</h5>
                </div>
              </div>

              <div class="item">
                <div className="company-slide bg1">
                  <div className="slider-icon">
                    {" "}
                    <img src={ecommerce} alt="Ecommerce and Retail" />{" "}
                  </div>
                  <h5>Ecommerce and Retail</h5>
                </div>
              </div>

              <div class="item">
                <div className="company-slide bg2">
                  <div className="slider-icon">
                    {" "}
                    <img src={bank} alt="Finance and Banking" />{" "}
                  </div>
                  <h5>Finance and Banking</h5>
                </div>
              </div>

              <div class="item">
                <div className="company-slide bg1">
                  <div className="slider-icon">
                    {" "}
                    <img
                      src={telecommunication}
                      alt="Telecommunications"
                    />{" "}
                  </div>
                  <h5>Telecommunications</h5>
                </div>
              </div>

              <div class="item">
                <div className="company-slide bg2">
                  <div className="slider-icon">
                    {" "}
                    <img src={govt} alt="Government and Public Sector" />{" "}
                  </div>
                  <h5>Government and Public Sector</h5>
                </div>
              </div>

              <div class="item">
                <div className="company-slide bg1">
                  <div className="slider-icon">
                    {" "}
                    <img src={tourism} alt="Hospitality and Toursim" />{" "}
                  </div>
                  <h5>Hospitality and Toursim</h5>
                </div>
              </div>

              <div class="item">
                <div className="company-slide bg2">
                  <div className="slider-icon">
                    {" "}
                    <img src={energy} alt="Energy and Utilities" />{" "}
                  </div>
                  <h5>Energy and Utilities</h5>
                </div>
              </div>
            </OwlCarousel>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature pt-2">
        <Container>
          <Row>
            <Col md={6} className="mb-4" data-aos="fade-right">
              <h4 className="mt-5 pt-4">OUR HISTORY </h4>
              <h2>About us</h2>
              <p>
                At Automation Services Ltd, we believe in the power of
                technology to transform businesses. As a software company
                specializing in IT solutions and services, we help businesses of
                all sizes leverage technology to improve their operations and
                increase their efficiency.
                <br />
                Since our inception, we have been committed to providing our
                clients with the best possible software solutions and services.
                We understand that every business is unique, and we work closely
                with our clients to understand their specific needs and
                requirements. This allows us to develop customized software
                solutions that are tailored to the unique needs of each
                business.
              </p>

              <Col md={12} className="mt-4">
                <NavLink exact to="/about-us">
                  <Button variant="primary btn-custom mt-3 btn-big">
                    Read Details
                    <FaArrowRight className="ms-5" />
                  </Button>
                </NavLink>
              </Col>
            </Col>

            <Col md={6} className="mt-5 mb-4" data-aos="fade-left">
              <img className="abouimg mt-4" src={about} alt="about" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature my-4 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mb-4">
              <h4>TECHNOLOGY PARTNER</h4>
              <h2>We Are Partnered With</h2>
            </Col>
          </Row>
          <Row>
            <OwlCarousel
              className="owl-theme slider-custom custom-industry slide-img2"
              {...optionsN}
              loop
              margin={20}
              nav
            >
              {partners?.map((partner) => (
                <div class="item" key={partner?.id}>
                  <div className="company-slide2">
                    <div className="slider-icon2">
                      {" "}
                      <img src={partner?.imgUrl} alt="client" />{" "}
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature pt-3 mb-5">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4">
              <div
                className="company-slide mission-vission"
                data-aos="flip-left"
              >
                <Row>
                  <Col md={3}>
                    <div className="slider-icon">
                      <img src={vision} alt="vision" />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Our Vision</h5>
                    <p className="mb-0">
                      Across the globe, the Enterprise ecosystem is evolving.
                      This has created a new scenario for businesses operating
                      in the market...
                    </p>
                    <NavLink exact to="/vision">
                      Read more...
                    </NavLink>
                  </Col>
                </Row>
              </div>

              <div
                className="company-slide mission-vission mt-4"
                data-aos="flip-right"
              >
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Our Mission</h5>
                    <p className="mb-0">
                      SAcross the globe, the Enterprise ecosystem is evolving.
                      This has created a new scenario for businesses operating
                      in the market. You either have robus...
                    </p>
                    <NavLink exact to="/mission">
                      Read more...
                    </NavLink>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon">
                      <img src={mission} alt="mission" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <div className="company-block-feature-two position-img">
                <img src={missionvision} alt="mission vision" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mt-5">
        <Container>
          <Row>
            <Col md={5} data-aos="fade-right">
              <img
                className="img-client mt-4"
                src={client}
                alt="client feedback"
              />
            </Col>

            <Col md={7} data-aos="fade-left">
              <Row>
                <Col md={12}>
                  <h4>CLIENTS' FEEDBACK</h4>
                  <h2>Testimonials</h2>
                </Col>
              </Row>

              <Carousel
                fade
                className="slider-custom slider-custom-two display-none-slide"
              >
                <Carousel.Item>
                  <Row>
                    <Col md={12}>
                      <div className="client-block">
                        <div className="client-top">
                          <img
                            src={avater}
                            alt="avater"
                            className="client-av"
                          />
                          <div className="client-details">
                            <h6 className="mb-1">Richard Roth</h6>
                            <label> United States </label>
                          </div>
                        </div>
                        <p className="detail-message">
                          We have been working with Automation Services Ltd from
                          last year and now for a very complicated project that
                          we are in, with lot of software technologies involved.
                          Automation Services Ltd has matched up to our
                          expectations and the deliverables were in time.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>

                <Carousel.Item>
                  <Row>
                    <Col md={12}>
                      <div className="client-block">
                        <div className="client-top">
                          <img
                            src={avater}
                            alt="avater"
                            className="client-av"
                          />
                          <div className="client-details">
                            <h6 className="mb-1">Swan Atkins</h6>
                            <label> United States </label>
                          </div>
                        </div>
                        <p className="detail-message">
                          The Automation Services Ltd team has always promptly
                          returned my calls or emails no matter what the time
                          is. I've found them to be consistently available and
                          committed to addressing my needs. I've already
                          recommended them many times and I will continue to do
                          so.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>Our Recent Works</h2>
            </Col>
          </Row>

          <Row>
            <OwlCarousel
              className="owl-theme slider-custom"
              {...options2}
              loop
              margin={20}
              nav
            >
              {recentWorks?.map((work) => (
                <div class="item" key={work?.id}>
                  <div className="work-block-slide">
                    {" "}
                    <img
                      className="img-width-full"
                      src={work?.imgUrl}
                      alt="recent work"
                    />{" "}
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </Row>
        </Container>
      </section>

      <div className="clearfix"></div>

      <Container>
        <div className="comapny-status">
          <Row>
            <Col md={12} className="text-center">
              <div className="company-status-block">
                <img src={partner} alt="partners" />
                <h3>12+</h3>
                <p>Tech Partners</p>
              </div>

              <div className="company-status-block">
                <img src={experience} alt="experience" />
                <h3>10+</h3>
                <p>Years of Experience</p>
              </div>

              <div className="company-status-block">
                <img src={countries} alt="countries" />
                <h3>15+</h3>
                <p>Countries Served</p>
              </div>

              <div className="company-status-block">
                <img src={brain} alt="brain" />
                <h3>100+</h3>
                <p>IT Professionals</p>
              </div>

              <div className="company-status-block">
                <img src={project} alt="project" />
                <h3>500+</h3>
                <p>Projects Completed</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Common>
  );
};

export default Home;
