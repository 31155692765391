import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Common from "../../layouts/Common";
import TermsAndConditionsEn from "./TermsAndConditionsEn";
import TermsAndConditionsBn from "./TermsAndConditionsBn";

export default function TermsAndConditions() {
  return (
    <Common>
      <section className="all-header"></section>

      <section className="all-company-feature mt-5">
        <Container>
          <Row>
            <Col md={12}>
              <TermsAndConditionsEn />
            </Col>

            <Col md={12}>
              <TermsAndConditionsBn />
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
}
