import React from "react";

function PolicyBn() {
  return (
    <div className="my-4">
      <div>
        <h2>অটোমেশন সার্ভিসেস লিমিটেডের গোপনীয়তা নীতি</h2>
        <p>
          <span className="font-weight-bold">সর্বশেষ আপডেট:</span> ফেব্রুয়ারি
          ১০, ২০২৫
        </p>
      </div>

      <hr className="opacity-25" />

      <p>
        <span className="font-weight-bold">অটোমেশন সার্ভিসেস লিমিটেড</span>,
        আপনার গোপনীয়তাকে গুরুত্ব দেয়। এই গোপনীয়তা নীতি আমাদের ওয়েবসাইট{" "}
        <a href="https://www.automationservicesbd.com" target="_blank">
          অটোমেশন সার্ভিসেস লিমিটেড
        </a>{" "}
        পরিদর্শন এবং আমাদের পরিষেবাগুলি ব্যবহারের সময় আপনার ব্যক্তিগত তথ্য
        সংগ্রহ, ব্যবহার, সংরক্ষণ এবং সুরক্ষার বিষয়ে বিশদ দেয়।
      </p>

      <div>
        <h3>আমরা কী ধরনের তথ্য সংগ্রহ করি</h3>
        <p>আমরা নিম্নলিখিত ধরনের তথ্য সংগ্রহ করতে পারি:</p>
        <ul>
          <li>
            <span className="font-weight-bold">ব্যক্তিগত তথ্য:</span> নাম, ইমেইল
            ঠিকানা, ফোন নম্বর, বিলিং তথ্য ইত্যাদি যা আপনি আমাদের পরিষেবা ব্যবহার
            বা রেজিস্টার করার সময় প্রদান করেন।
          </li>
          <li>
            <span className="font-weight-bold">ব্যবহার তথ্য:</span> আমাদের
            ওয়েবসাইটে আপনার অ্যাক্সেস এবং ইন্টারঅ্যাকশন সম্পর্কে তথ্য, যেমন
            আইপি ঠিকানা, ব্রাউজার টাইপ, ডিভাইস তথ্য, এবং ব্যবহার প্যাটার্ন।
          </li>
          <li>
            <span className="font-weight-bold">
              কুকি এবং ট্র্যাকিং প্রযুক্তি:
            </span>{" "}
            আমরা কুকি এবং অনুরূপ প্রযুক্তি ব্যবহার করে আমাদের ওয়েবসাইটে আপনার
            পছন্দ এবং ক্রিয়াকলাপ সম্পর্কে তথ্য সংগ্রহ করি।
          </li>
        </ul>
      </div>

      <div>
        <h3>আমরা আপনার তথ্য কীভাবে ব্যবহার করি</h3>
        <p>
          সংগ্রহ করা তথ্যের মাধ্যমে আমরা নিম্নলিখিত উদ্দেশ্যে এটি ব্যবহার করতে
          পারি:
        </p>
        <ul>
          <li>আমাদের পরিষেবাগুলি প্রদান এবং উন্নত করতে।</li>
          <li>
            আপনার সাথে যোগাযোগ করতে, প্রশ্নের উত্তর দিতে এবং পরিষেবার আপডেট
            পাঠাতে।
          </li>
          <li>আমাদের ওয়েবসাইটে আপনার অভিজ্ঞতা ব্যক্তিগতকরণ করতে।</li>
          <li>আইনি বাধ্যবাধকতা পূরণ করতে এবং আমাদের শর্তাবলী প্রয়োগ করতে।</li>
        </ul>
      </div>

      <div>
        <h3>তথ্য সুরক্ষা</h3>
        <p>
          আমরা আপনার ব্যক্তিগত তথ্য অননুমোদিত অ্যাক্সেস, পরিবর্তন বা ধ্বংস থেকে
          রক্ষা করতে যুক্তিসঙ্গত ব্যবস্থা গ্রহণ করি। তবে, দয়া করে মনে রাখবেন যে
          কোনও তথ্য ট্রান্সমিশন বা সংরক্ষণ পদ্ধতি ১০০% নিরাপদ নয়।
        </p>
      </div>

      <div>
        <h3>আপনার তথ্য শেয়ার করা</h3>
        <p>
          আমরা তৃতীয় পক্ষের পরিষেবা প্রদানকারীদের সাথে আপনার তথ্য শেয়ার করতে
          পারি যারা আমাদের ব্যবসা পরিচালনায় সহায়তা করে, যেমন পেমেন্ট প্রসেসর
          এবং হোস্টিং পরিষেবা প্রদানকারী। আমরা কখনই আপনার ব্যক্তিগত তথ্য তৃতীয়
          পক্ষকে বিক্রি বা ভাড়া দিই না।
        </p>
      </div>

      <div>
        <h3>আপনার অধিকার</h3>
        <p>আপনার অধিকার রয়েছে:</p>
        <ul>
          <li>আপনার ব্যক্তিগত তথ্য অ্যাক্সেস, আপডেট বা মুছতে।</li>
          <li>
            আপনার তথ্য সংগ্রহ এবং ব্যবহার করার জন্য অনুমতি প্রত্যাহার করতে।
          </li>
          <li>
            নির্দিষ্ট পরিস্থিতিতে আপনার ব্যক্তিগত তথ্য প্রক্রিয়া করার বিরুদ্ধে
            আপত্তি জানাতে।
          </li>
        </ul>
      </div>

      <div>
        <h3>তৃতীয় পক্ষের লিংক</h3>
        <p>
          আমাদের ওয়েবসাইটে তৃতীয় পক্ষের ওয়েবসাইটের লিংক থাকতে পারে। আমরা সেই
          ওয়েবসাইটগুলোর গোপনীয়তা নীতির জন্য দায়ী নই এবং আপনাকে তাদের
          গোপনীয়তা নীতি পর্যালোচনা করার জন্য উৎসাহিত করি।
        </p>
      </div>

      <div>
        <h3>এই গোপনীয়তা নীতির পরিবর্তন</h3>
        <p>
          আমরা সময়ে সময়ে এই গোপনীয়তা নীতি আপডেট করতে পারি। কোনো পরিবর্তন এই
          পৃষ্ঠায় পোস্ট করা হবে এবং "প্রযোজ্য তারিখ" আপডেট করা হবে। দয়া করে
          সময়ে সময়ে এই পৃষ্ঠাটি চেক করুন।
        </p>
      </div>

      <div>
        <h3>যোগাযোগ করুন</h3>
        <p>
          এই গোপনীয়তা নীতি বা আমাদের তথ্য চর্চা সম্পর্কে আপনার যদি কোনো প্রশ্ন
          বা উদ্বেগ থাকে, তাহলে আমাদের সাথে যোগাযোগ করুন:
        </p>
        <ul>
          <li>ইমেইল: info@automationservicesbd.com</li>
          <li>ফোন: 880 1847 364080</li>
        </ul>
      </div>
    </div>
  );
}

export default PolicyBn;
