import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Button from "react-bootstrap/Button";
import "./Footer.css";
import logo from "../assets/images/logo/asl_logo.png";
import { NavLink } from "react-router-dom";
import { FaPeriscope } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";

import bd from "../assets/images/bd.svg";
import usa from "../assets/images/usa.webp";
import FreeQuote from "../pages/FreeQuote";
import Modal from "react-bootstrap/Modal";
import BackToTop from "react-back-to-top-button";
const Footer = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="footer-area">
      <section className="all-company-feature mtp mb-150">
        <div className="bg-get-quote">
          <Container>
            <Row>
              <Col md={8}>
                <h6>GET IN TOUCH</h6>
                <h3>Ready to Bring Your Vision to Life?</h3>
              </Col>
              <Col md={4} className="text-end">
                <Button
                  variant="primary btn-custom mt-3 btn-big border-white w260"
                  onClick={handleShow}
                >
                  Get a Free Quote
                  <FaArrowRight className="ms-5" />
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
        <Modal.Body>
          <FreeQuote />
        </Modal.Body>
      </Modal>

      <Container>
        <Row>
          <Col md={3}>
            <h3>
              <NavLink className="logohideone" exact to="/home">
                <img src={logo} alt="logo" className="footerlogo" />
              </NavLink>
            </h3>
            <p className="text-gray mt-3 jus-text">
              Automation Services Ltd, founded by experienced IT professionals
              in 2015 in Dhaka, specializes in ICX billing and electricity
              prepaid meter systems, delivering high-quality, cost-effective
              software solutions.
            </p>
          </Col>
          <Col md={3} className="link-list company-list-link">
            <div>
              <h3>Services</h3>

              <ul className="list-group list-inline">
                <li>
                  <NavLink exact to="/software-web-development">
                    Software & Web Development
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/devops">
                    DevOps
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/hire-offshore">
                    Hire & Offshore
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/digital-marketing">
                    Digital Marketing
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/ai">
                    Artificial Intelligence
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/big-data">
                    Big Data
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/terms-conditions">
                    Terms & Conditions
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/privacy-policy">
                    Privacy Policy
                  </NavLink>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={3}>
            <h3>
              {" "}
              <img src={usa} alt="usa" className="flag" />
              United States
            </h3>
            <ul className="list-group list-inline company-list-link">
              <li>
                {" "}
                <span>
                  <FaPeriscope />
                </span>
                <label>
                  LLC 152-15A 64Th Circle Suite 1C Fresh Meadows, NY, 11365,
                  USA.
                </label>
              </li>
              <li>
                {" "}
                <span>
                  <FaMobileAlt />
                </span>
                <label>+1 (347) 698-5455</label>
              </li>
            </ul>
          </Col>

          <Col md={3}>
            <h3>
              <img src={bd} alt="bd" className="flag" />
              Bangladesh
            </h3>
            <ul className="list-group list-inline company-list-link mt-2">
              <li>
                {" "}
                <span>
                  <FaPeriscope />
                </span>
                <label>House- 78, Road-12, Block- E, Banani, Dhaka-1213</label>
              </li>
              <li>
                {" "}
                <span>
                  <FaMobileAlt />
                </span>
                <label>+880 1847-364081</label>
              </li>
            </ul>
            <ul className="list-group list-inline company-list-link">
              <li>
                {" "}
                <span>
                  <FaPeriscope />
                </span>
                <label>
                  {" "}
                  CASABELLA house no -17 , road no- 128, gulshan-1, Dhaka-1212{" "}
                </label>
              </li>
              <li>
                {" "}
                <span>
                  <FaMobileAlt />
                </span>
                <label>+880 1847 364080</label>
              </li>
            </ul>
          </Col>
        </Row>
        <hr className="hr mt-3 mb-3" />
        <Row className="footer-bottom">
          <Col md={6}>
            <p> © 2022-2023 All Rights Reserved By ASL</p>
          </Col>
          <Col md={6}>
            <div className="social-icon">
              <NavLink exact to="">
                <Button variant="btn">
                  <FaFacebookF />
                </Button>
              </NavLink>
              <NavLink exact to="">
                <Button variant="btn">
                  <FaTwitter />
                </Button>
              </NavLink>

              <Button variant="btn">
                <a href="https://www.linkedin.com/mwlite/company/nexalinx">
                  <FaLinkedinIn />
                </a>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <BackToTop
        showOnScrollDown
        showAt={1200}
        speed={1500}
        easing="easeInOutQuint"
      >
        <button className="back-to-top">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
          </svg>
        </button>
      </BackToTop>
    </div>
  );
};

export default Footer;
