import React, { useState } from "react";
// import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";
import {
  FaArrowRight,
  FaBuilding,
  FaCommentAlt,
  FaCommentDots,
  FaMobileAlt,
  FaUserAlt,
} from "react-icons/fa";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { toast } from "react-hot-toast";

const ContactTouch = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    let formdata = new FormData();
    formdata.append("name", inputs.name);
    formdata.append("email", inputs.email);
    formdata.append("phone", inputs.phone);
    formdata.append("subject", inputs.subject);
    formdata.append("comment", inputs.comment);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };

    try {
      const res = await fetch(
        `${baseURL}/api/contact/form`,
        requestOptions
      ).then((response) => response.json());

      if (res?.status && res?.code === 200) {
        setInputs({});
        return toast.success(res?.message);
      } else {
        return toast.error("Message not sent!");
      }
    } catch (error) {
      return toast.error("Message not sent!");
    }
  };

  return (
    <div className="get-touch-form border-top-0">
      <Form onSubmit={handleSubmit}>
        <div className="mb-3 me-0 sch-block">
          <h3 className="h3-schedule mb-5 mt-5">
            {" "}
            <strong>Leave Us A Message</strong>{" "}
          </h3>
        </div>
        <Form.Group className="mb-4 pos-re">
          {" "}
          <span>
            <FaUserAlt />
          </span>
          <Form.Control
            type="text"
            name="name"
            value={inputs.name || ""}
            onChange={handleChange}
            placeholder="Name"
          />
        </Form.Group>
        <Form.Group className="mb-4 pos-re" controlId="">
          {" "}
          <span>
            <FaCommentAlt />
          </span>
          <Form.Control
            type="email"
            name="email"
            value={inputs.email || ""}
            onChange={handleChange}
            placeholder="Email"
            required
          />
        </Form.Group>
        <Form.Group className="mb-4 pos-re">
          {" "}
          <span>
            <FaMobileAlt />
          </span>
          <Form.Control
            type="text"
            name="phone"
            value={inputs.phone || ""}
            onChange={handleChange}
            placeholder="Contact Number"
          />
        </Form.Group>
        <Form.Group className="mb-4 pos-re">
          {" "}
          <span>
            <FaBuilding />
          </span>
          <Form.Control
            type="text"
            name="subject"
            value={inputs.subject || ""}
            onChange={handleChange}
            placeholder="Subject"
          />
        </Form.Group>
        <Form.Group
          className="mb-4 pos-re"
          controlId="exampleForm.ControlTextarea1"
        >
          {" "}
          <span className="comspan">
            <FaCommentDots />
          </span>
          <Form.Control
            as="textarea"
            className="textform"
            name="comment"
            value={inputs.comment || ""}
            onChange={handleChange}
            placeholder="Comment"
            rows={2}
          />
        </Form.Group>
        <Col md={12} className="text-center mt-4">
          <Button variant="primary btn-custom mt-3 btn-big" type="submit">
            Submit
            <FaArrowRight className="ms-5" />
          </Button>
        </Col>
      </Form>
    </div>
  );
};

export default ContactTouch;
