import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Common from "../../layouts/Common";
import PolicyEn from "./PolicyEn";
import PolicyBn from "./PolicyBn";

function Policy() {
  return (
    <Common>
      <section className="all-header"></section>

      <section className="all-company-feature mt-5">
        <Container>
          <Row>
            <Col md={12}>
              <PolicyEn />
            </Col>

            <Col md={12}>
              <PolicyBn />
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
}

export default Policy;
