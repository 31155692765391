import React from "react";
import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";

import missionicon from "../../assets/images/view/missionicon.svg";
import visionicon from "../../assets/images/view/visionicon.svg";
import historyicon from "../../assets/images/view/historyicon.svg";

const Vision = () => {
  return (
    <Common>
      <section className="all-header"></section>

      <section className="all-company-feature bg-deep-gray bg-deep-gray2 mt-3 pt-3 bg-white certification-slide blog-wr">
        <Container>
          <Row>
            <Col md={12} className="mt-5 mb-4 text-center zindex-all">
              <h2 className="member-h2">Insights</h2>
              <p className="text-center ps-4 pe-4">
                {" "}
                Across the globe, the Enterprise ecosystem is evolving. This has
                created a new scenario for businesses operating in the market.
                You either have robust and flexible services or solutions that
                are fast to the market or you are dead in the water, there is no
                in between! Automation Services Ltd help clients ensure that
                they stay relevant in this market. What we do is simple, we
                offer our clients the best, and by far the most cost-effective,
                software and outsourcing solutions provider in the market. We
                ensure that our clients have a business roadmap, perfectly in
                sync with the changing needs.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature">
        <Container>
          <div
            className="company-slide mission-vission mt-0 mis-vis border-0"
            data-aos="flip-left"
          >
            <Row>
              <Col md={3}>
                <div className="slider-icon">
                  {" "}
                  <img
                    className="missionv"
                    src={missionicon}
                    alt="missionicon"
                  />{" "}
                </div>
              </Col>
              <Col md={9} className="ps-0">
                <h5 className="p-0">Mission – Why We Exist</h5>
                <p className="mb-0">
                  Simply put, our mission is to understand and address the needs
                  of our clients through a holistic approach built on three ‘Ts’
                  – Trust, Team Work and Technology. We exist to make our
                  clients’ life easy, with the integration of the most
                  appropriate technology and people. Our aim is to remain who we
                  are at the core while pushing our limits to make clients and
                  thereby end users happy.
                </p>
              </Col>
            </Row>
          </div>

          <div
            className="company-slide mission-vission mt-4 mis-vis border-0"
            data-aos="flip-right"
          >
            <Row>
              <Col md={9} className="pe-0 text-end">
                <h5 className="p-0">Vision – The Potential Inherent</h5>
                <p className="mb-0">
                  {" "}
                  We believe that if it’s not life-changing, it’s not worth a
                  try. Rightly so, we constantly innovate to create solutions
                  that align with our clients’ values that enhance the way they
                  do business. We go that extra mile to churn out the most
                  unique and trendsetting business practices that enable us to
                  remain a trustful software services partner and leave that
                  lasting foot-mark on the global industry.
                </p>
              </Col>
              <Col md={3}>
                <div className="slider-icon">
                  {" "}
                  <img
                    className="missionv"
                    src={visionicon}
                    alt="visionicon"
                  />{" "}
                </div>
              </Col>
            </Row>
          </div>

          <div
            className="company-slide mission-vission mt-4 mis-vis border-0"
            data-aos="flip-left"
          >
            <Row>
              <Col md={3}>
                <div className="slider-icon">
                  {" "}
                  <img
                    className="missionv"
                    src={historyicon}
                    alt="historyicon"
                  />{" "}
                </div>
              </Col>
              <Col md={9} className="ps-0">
                <h5 className="p-0">History</h5>
                <p className="mb-0">
                  True to the saying, Automation Services Ltd, in our foray into
                  challenging projects and unfamiliar business arenas,
                  constantly retrospect at the way we have evolved as an
                  organization. Automation Services Ltd incubated itself as a
                  company that offered niche products and solutions. A lot has
                  changed on the go. Business models got refined, quantifiable
                  results replaced redundant workplace practices, team work
                  replaced individual performances, and the best for the job
                  were hired and retained.
                </p>
              </Col>
            </Row>
          </div>

          <div
            className="company-slide mission-vission mt-4 border-0"
            data-aos="flip-left"
          >
            <Row>
              <Col md={12}>
                <p className="mb-0">
                  A decade long journey had taught the firm that diversification
                  and contingency planning doesn’t mean waste of time but meant
                  better business. Learning the market and testing new roadmaps
                  meant better results for our key stakeholders. We realized
                  that splitting into teams could get the job done faster. When
                  the core team could carry out the overall operations and
                  strategy formulation, the other team – the tech team could
                  keep themselves updated with the latest technologies and
                  trends.
                  <br />
                  <br />
                  What we learned through time is not changing. But something
                  that does change all the time is the exclusive way we
                  implement new and innovative business solutions. Whatever we
                  offer today, be it hiring experienced and proficient
                  developers for your project or delivering customized and
                  high-end mobile applications, the wisdom that comes along with
                  our services is definitely rooted in our radically fought out
                  history.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Common>
  );
};

export default Vision;
