import React from "react";
import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";

import { FaQuoteLeft, FaArrowCircleRight } from "react-icons/fa";

import blog1 from "../../assets/images/view/blog1.jpg";

const Blogdetails = () => {
  return (
    <Common>
      <section className="all-header"></section>

      <section className="all-company-feature bg-deep-gray bg-deep-gray2 mt-3 pt-3 bg-white certification-slide blog-wr">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4 text-center">
              <img src={blog1} alt="blog1" className="blog-details" />

              <div class="entry-meta text-start mt-4">
                <ul>
                  <li>
                    <span>Posted On:</span>October 02, 2022
                  </li>
                  <li>
                    <span>Posted By:</span>Muhammad Shahidul Islam Khan Rumi
                  </li>
                </ul>
                <h4 className="h4blog mt-3">
                  Differences Between MongoDB & MySQL
                </h4>
                <p className="mt-2 p-blog">
                  When it comes to database management systems, MySQL and
                  MongoDB are certainly the most popular among developers.
                  <br />
                  <br />
                  Relational systems such as MySQL use tables (containing rows
                  and columns) to store data and make use of structured query
                  languages (such as SQL) for data access. An SQL query
                  generated by users to retrieve data from a MySQL database
                  joins multiple tables together to create a view of the data
                  they require.
                  <br />
                  <br />
                  On the other hand, MongoDB - which is essentially a
                  non-relational/non-SQL system - represents information as a
                  series of JSON-like documents (in contrast to the table and
                  row format of relational systems).
                  <br />
                  <br />
                  Both MongoDB and MySQL are similar in the fact that they are
                  free and open-source services. In this article, we take a look
                  at the differences and trade-offs between them to determine
                  which would be a perfect fit for designing your database.
                </p>
              </div>

              <blockquote className="block-d">
                <FaQuoteLeft />
                <h6>Speed & Performance</h6>
                <p>
                  Owing to the different approaches to data storage and
                  retrieval by both management systems, assessing the
                  performance of MongoDB and MySQL against each other is very
                  difficult. While standard SQL benchmarks can help one to
                  easily compare two SQL databases, doing the same between
                  relational (SQL) and non-relational (MongoDB) is much more
                  subjective and open to interpretation. All that being said,
                  MySQL can quickly select large numbers of records, while
                  MongoDB is much quicker at inserting or updating huge numbers
                  of records.
                </p>
              </blockquote>

              <div className="block-d">
                <h5 class="text-start">
                  <span>
                    <FaArrowCircleRight />
                  </span>
                  User-Friendliness & Data Handling
                </h5>
                <p>
                  MongoDB is an attractive option to developers. Its simple data
                  storage philosophy can be easily understood by anybody with
                  programming experience.
                  <br />
                  <br />
                  MongoDB supports the ACID properties of data transactions
                  (atomicity, consistency, isolation, and durability). This
                  allows for more flexibility when developers try to build a
                  transactional data model that can horizontally scale in a
                  distributed environment. At the same time, such model designs
                  will have no impact on performance for transactions between
                  multiple documents.
                  <br />
                  <br />
                  Transactional support in MySQL, however, is more complex. As
                  the need for better data management and bigger data volume
                  grew, MySQL began to be seen as a more rigid architecture and
                  not as flexible for reformatting data structures. This is
                  largely due to the tabular design, which allows sites and apps
                  to apply only a limited set of multivariate search queries.
                </p>

                <h5 class="text-start mt-5">
                  <span>
                    <FaArrowCircleRight />
                  </span>
                  Scalability
                </h5>
                <p>
                  Sharding is a method for distributing data across multiple
                  machines. MongoDB uses sharding to support deployments that
                  contain large datasets and involve operations with high
                  throughput.
                  <br />
                  <br />
                  A clear advantage MongoDB has over MySQL is its capability for
                  horizontal scaling. Using shard clustering, MongoDB
                  distributes data across machines, which helps use cases that
                  include working with large data sets.
                  <br />
                  <br />
                  MySQL does not support sharding out of the box. Scaling a
                  database in MySQL (and many other popular RDBMS solutions)
                  requires either changing the application using the database or
                  allowing for downtime until the migration to a larger server
                  is complete.
                  <br />
                  <br />
                  That being said, MySQL is compatible with more systems than
                  MongoDB because of its mobility and how long it has been on
                  the market. Hence some may see this as a more scalable
                  solution, depending on their system of choice.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default Blogdetails;
