import React from "react";

export default function TermsAndConditionsEn() {
  return (
    <div className="my-4">
      <div>
        <h2>Terms and Conditions for Automation Services Ltd</h2>
        <p>
          <span className="font-weight-bold">Last updated:</span> February 10,
          2025
        </p>
      </div>

      <hr className="opacity-25" />

      <div>
        <h3>Introduction</h3>
        <p>
          Welcome to{" "}
          <span className="font-weight-bold">Automation Services Ltd</span>{" "}
          ("Company," "we," "our," "us"). By accessing or using our website{" "}
          <a href="https://www.automationservicesbd.com" target="_blank">
            Automation Services Ltd
          </a>{" "}
          and services, you agree to comply with and be bound by these Terms and
          Conditions. If you do not agree with any part of these terms, please
          do not use our services.
        </p>
      </div>

      <div>
        <h3>Services</h3>
        <p>
          Automation Services Ltd provides IT solutions, automation services,
          and other technology-related offerings. The use of our services is
          subject to the following terms:
        </p>
        <ul>
          <li>You must use our services lawfully and ethically.</li>
          <li>
            We reserve the right to modify, suspend, or discontinue any part of
            our services without prior notice.
          </li>
        </ul>
      </div>

      <div>
        <h3>User Responsibilities</h3>
        <ul>
          <li>
            You agree not to use our services for any unlawful activities.
          </li>
          <li>
            You must ensure the accuracy of the information you provide to us.
          </li>
          <li>Any misuse of our services may lead to account termination.</li>
        </ul>
      </div>

      <div>
        <h3>Intellectual Property Rights</h3>
        <ul>
          <li>
            All content, logos, and trademarks on our website are the property
            of Automation Services Ltd.
          </li>
          <li>
            You may not reproduce, distribute, or modify any content without our
            written permission.
          </li>
        </ul>
      </div>

      <div>
        <h3>Pricing and Payments</h3>
        <ul>
          <li>
            Pricing for services is listed on our website or as agreed in
            contracts.
          </li>
          <li>Payments must be made according to the agreed terms.</li>
          <li>
            We reserve the right to change our pricing at any time with notice.
          </li>
        </ul>
      </div>

      <div>
        <h3>Limitation of Liability</h3>
        <ul>
          <li>We do not guarantee uninterrupted or error-free services.</li>
          <li>
            We are not liable for any direct, indirect, or incidental damages
            arising from the use of our services.
          </li>
        </ul>
      </div>

      <div>
        <h3>Privacy Policy</h3>
        <ul>
          <li>
            Your use of our services is also governed by our{" "}
            <a
              href="https://www.automationservicesbd.com/#/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </li>
          <li>
            We collect and store user data as per applicable laws and our
            privacy practices.
          </li>
        </ul>
      </div>

      <div>
        <h3>Third-Party Links</h3>
        <p>
          Our website may contain links to third-party websites. We are not
          responsible for the content or policies of those websites.
        </p>
      </div>

      <div>
        <h3>Termination</h3>
        <p>
          We reserve the right to terminate or restrict access to our services
          if users violate these Terms and Conditions.
        </p>
      </div>

      <div>
        <h3>Changes to These Terms</h3>
        <p>
          We may update these Terms and Conditions at any time. Continued use of
          our services after changes constitutes acceptance of the new terms.
        </p>
      </div>

      <div>
        <h3>Governing Law</h3>
        <p>
          These Terms are governed by the laws of{" "}
          <span className="font-weight-bold">Bangladesh</span>. Any disputes
          will be resolved in the courts of Bangladesh.
        </p>
      </div>

      <div>
        <h3>Contact Us</h3>
        <p>
          If you have any questions regarding these Terms and Conditions, please
          contact us at:
        </p>
        <ul>
          <li>Email: info@automationservicesbd.com</li>
          <li>Phone: 880 1847 364080</li>
        </ul>
      </div>
    </div>
  );
}
