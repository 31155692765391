import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { FaHome } from "react-icons/fa";
import { NavLink } from "react-router-dom";

import logo from "../assets/images/logo/asl_logo.png";

import "./Nav.css";

const Navtop = () => {
  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };
  return (
    <div>
      <Navbar className={`customHeader ${stickyClass}`} expand="lg">
        <Container>
          <Row>
            <Col md={3} className="nav-grid-cutom">
              <div className="navLogo">
                <NavLink className="logohideone" exact to="/home">
                  <img src={logo} alt="logo" />
                </NavLink>
              </div>
            </Col>
            <Col md={7} className="nav-grid-cutom">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto custom-navbar">
                  <NavLink exact to="/home">
                    <FaHome className="me-1 home-mar" />
                    Home
                  </NavLink>

                  <NavDropdown
                    title="Services"
                    id="basic-nav-dropdown"
                    className="common-drop-down drop-down-nav custom-navbar-list"
                  >
                    <Row>
                      <Col lg={12}>
                        <NavDropdown.Item>
                          <NavLink exact to="/software-web-development">
                            Software & Web Development
                          </NavLink>
                          <NavLink exact to="/mobile-app-development">
                            Mobile App Development
                          </NavLink>
                          <NavLink exact to="/quality-assurance">
                            Quality Assurance
                          </NavLink>
                          <NavLink exact to="/devops">
                            DevOps
                          </NavLink>
                          <NavLink exact to="/hire-offshore">
                            Hire & Offshore
                          </NavLink>
                          <NavLink exact to="/staff-augmentation">
                            Staff Augmentation
                          </NavLink>
                          <NavLink exact to="/digital-marketing">
                            Digital Marketing
                          </NavLink>
                          <NavLink exact to="/ai">
                            Artificial Intelligence
                          </NavLink>
                          <NavLink exact to="/big-data">
                            Big Data
                          </NavLink>
                        </NavDropdown.Item>
                      </Col>
                    </Row>
                  </NavDropdown>

                  <NavLink exact to="/portfolio">
                    Portfolio
                  </NavLink>

                  <NavLink exact to="/blog">
                    Blog
                  </NavLink>

                  <NavDropdown
                    title="About US"
                    id="basic-nav-dropdown"
                    className="common-drop-down drop-down-nav custom-navbar-list"
                  >
                    <Row>
                      <Col lg={12}>
                        <NavDropdown.Item>
                          <NavLink exact to="/about-us">
                            About Us
                          </NavLink>
                          <NavLink exact to="/team">
                            Our Team
                          </NavLink>
                        </NavDropdown.Item>
                      </Col>
                    </Row>
                  </NavDropdown>

                  <NavLink className="activenone" exact to="/contact">
                    <Col md={2} className="navButton dis-hide-show">
                      <Button variant="primary btn-custom mt-1 ps-4 pe-4">
                        Get In Touch
                      </Button>
                    </Col>
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
            </Col>
            <Col md={2} className="navButton activenone dis-none">
              <NavLink className="activenone" exact to="/contact">
                <Button variant="primary btn-custom mt-1 ps-4 pe-4">
                  Get In Touch
                </Button>
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navtop;
