import React from "react";

import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";

import {
  FaAppStoreIos,
  FaCheckCircle,
  FaHandPointRight,
  FaServer,
  FaScrewdriver,
} from "react-icons/fa";
import { IoLogoAndroid } from "react-icons/io";
import { MdDesignServices, MdDeveloperMode } from "react-icons/md";

import service1 from "../../assets/images/view/service1.svg";
import service2 from "../../assets/images/view/service2.svg";

import ContactForm from "../../components/ContactForm";

const MobileAppDevelopment = () => {
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>Mobile App Development</strong>
                </h1>
                <p className="text-white p20 mb-4">
                  Our company is your go-to partner for cutting-edge mobile app
                  development. We craft exceptional applications that cater to
                  both iOS and Android users, ensuring seamless functionality
                  and an engaging experience. Our talented team blends
                  creativity with technical expertise, developing apps that not
                  only meet your business objectives but also resonate with your
                  target audience. From concept to launch, we prioritize
                  user-friendly designs and robust security measures.
                  Collaborate with ASL to bring your mobile app vision to life,
                  empowering your business in the ever-evolving digital
                  landscape.
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Cross-Platform Solutions</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>User-Centric Design</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>High Performance</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Robust Security</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Scalable Architecture</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Continuous Updates</label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>

      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
              <h2>Our Mobile Application Services</h2>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaAppStoreIos />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">iOS App Development</h5>
                    <p className="mb-0">
                      Creating applications specifically for Apple devices,
                      focusing on optimal performance and user experience. This
                      involves adhering to Apple’s design guidelines and
                      utilizing the latest technologies to build intuitive and
                      engaging apps for iPhones and iPads.
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Android App Development</h5>
                    <p className="mb-0">
                      Developing applications for Android devices with a strong
                      emphasis on user-friendly interfaces and robust
                      functionality. This includes leveraging the Android SDK
                      and ensuring compatibility across a diverse range of
                      devices and operating system versions.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <IoLogoAndroid />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={service1} alt="service" />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <MdDeveloperMode />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Cross-Platform Development</h5>
                    <p className="mb-0 pb-3">
                      Utilizing frameworks like React Native or Flutter to
                      create applications that function seamlessly on both iOS
                      and Android platforms. This approach not only reduces
                      development time and costs but also ensures a consistent
                      user experience across different devices.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">UI/UX Design for Mobile Apps</h5>
                    <p className="mb-0">
                      Crafting visually appealing user interfaces and seamless
                      user experiences tailored for mobile devices. This process
                      focuses on intuitive navigation and usability, enhancing
                      overall user satisfaction and retention.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <MdDesignServices />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">App Integration and Testing</h5>
                    <p className="mb-0 pb-4">
                      Integrating mobile applications with existing systems and
                      third-party services to enhance functionality, as well as
                      conducting thorough testing to ensure optimal performance.
                      This includes functional, performance, and usability
                      testing to identify and resolve issues prior to launch.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Maintenance and Updates</h5>
                    <p className="mb-0">
                      Providing ongoing support for mobile applications,
                      including regular updates, bug fixes, and feature
                      enhancements to maintain compatibility with the latest
                      operating systems. Additionally, developing a
                      comprehensive marketing strategy for the app's launch,
                      focusing on app store optimization (ASO) and user
                      acquisition to maximize visibility and downloads.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaScrewdriver />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
            <img className="width-full mt-5" src={service2} alt="service" />
          </Col>
          <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
            <h2 className="mt-5 pt-4">
              Why Choose Us for Mobile App Development?
            </h2>
            <ul className="list-inline list-group ul-list-about mt-4">
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>
                  Comprehensive development for iOS, Android, and cross-platform
                </p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>User-centric design focused on engagement and retention</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Integration of the latest technologies and trends</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Robust testing to ensure performance and reliability</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Ongoing support and maintenance after launch</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Common>
  );
};

export default MobileAppDevelopment;
