import React from "react";

import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

import spring from "../../assets/images/technology/spring.svg";
import java from "../../assets/images/technology/java.svg";
import struts from "../../assets/images/technology/struts.svg";
import reacjs from "../../assets/images/technology/reacjs.svg";
import python from "../../assets/images/technology/python.svg";
import mongo from "../../assets/images/technology/mongo.svg";
import angular from "../../assets/images/technology/angular.svg";
import dotnet from "../../assets/images/technology/dotnet.svg";
import node from "../../assets/images/technology/node.svg";
import js from "../../assets/images/technology/js.svg";

import adminpanel from "../../assets/images/portfolio/web/adminpanel.webp";
import classifiedad from "../../assets/images/portfolio/web/classifiedad.webp";
import erp from "../../assets/images/portfolio/web/erp.webp";
import fdm from "../../assets/images/portfolio/web/fdm.webp";
import his from "../../assets/images/portfolio/web/his.webp";
import icx from "../../assets/images/portfolio/web/icx.webp";
import radioproject from "../../assets/images/portfolio/web/radioproject.webp";
import screenwriting from "../../assets/images/portfolio/web/screenwriting.webp";
import screenwriting2 from "../../assets/images/portfolio/web/screenwriting2.webp";
import socialuid from "../../assets/images/portfolio/web/socialuid.webp";
import tourplan from "../../assets/images/portfolio/web/tourplan.webp";
import vexanime from "../../assets/images/portfolio/web/vexanime.webp";
import zoomclone from "../../assets/images/portfolio/web/zoomclone.webp";

const Webapp = () => {
  return (
    <div>
      <Row className="mt-5 pt-5">
        <Col md={7} className="mt-3">
          <h2 className="mt-5">ERP Solution</h2>
          <p className="pe-5">
            Our ERP solution offers comprehensive modules for financial
            accounts, project management, supply chain management, and more. It
            streamlines business operations, enhances efficiency, and provides
            real-time insights for informed decision-making.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={java} alt="java" />
            </span>
            <p>Java</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={spring} alt="spring boot" />
            </span>
            <p>Spring Boot</p>
          </div>
          <div className="clearfix"></div>
        </Col>
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img
                src={erp}
                className="portimg"
                alt="ERP Solution"
                style={{ width: "100%" }}
              />{" "}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={fdm} className="portimg" alt="Field Data Management" />{" "}
            </div>
          </div>
        </Col>
        <Col md={7} className="mt-3 ps-5">
          <h2 className="mt-5">Field Data Management</h2>
          <p className="pe-5">
            streamline field operations with our Field Data Management solution.
            From customer information updates to worl order creation and
            dispatch, our modules empower teams to efficiently manage tasks and
            resources in the field.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={java} alt="java" />
            </span>
            <p>Java</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={struts} alt="struts" />
            </span>
            <p>Struts</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={7} className="mt-3">
          <h2 className="mt-5">ICX Billing Solution</h2>
          <p className="pe-5">
            Our ICX Billing Solution provides mediation, billing, CDR analysis,
            and conversion capabilities for ICX operators. It ensures accurate
            billing and revenue management for international traffic, enhancing
            opera- tional efficiency and profitability
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={java} alt="java" />
            </span>
            <p>Java</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={spring} alt="spring boot" />
            </span>
            <p>Spring Boot</p>
          </div>
          <div className="clearfix"></div>
        </Col>
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={icx} className="portimg" alt="ICX Billing Solution" />{" "}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img
                src={his}
                className="portimg"
                alt="Hospital Information System"
              />{" "}
            </div>
          </div>
        </Col>
        <Col md={7} className="mt-3 ps-5">
          <h2 className="mt-5">Hospital Information System</h2>
          <p className="pe-5">
            Transform hospital operations with our Hospital Information System.
            From patient and doctor management to pharmacy and diagnostic center
            management, our system streamlines processes, improves patient care,
            and enhances administra- tive efficiency
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reacjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={spring} alt="spring boot" />
            </span>
            <p>Spring Boot</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={7} className="mt-3">
          <h2 className="mt-5">Survey Project</h2>
          <p className="pe-5">
            {" "}
            The survey project involved creating a user-friendly survey platform
            that collected and analyzed customer feedback from different
            websites. The project team developed a web crawler that gathered
            customer reviews and ratings and used sentiment analysis to
            categorize the feedback as positive, negative, or neutral. The
            platform also included a user interface that allowed users to search
            for specific products or services and view the associated feedback
            in easy-to-understand graphs and charts. The team conducted
            extensive testing to ensure the accuracy and reliability of the
            platform, including assessing the quality of the data collected and
            testing the functionality and performance of the platform. The
            project aimed to provide users with valuable insights into customer
            sentiment towards different products and services, using sentiment
            analysis and easy-to-understand visualizations to simplify the
            process of analyzing and comparing feedback from multiple sources.{" "}
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={python} alt="python" />
            </span>
            <p>Django</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img className="mg-icon" src={mongo} alt="mongo" />
            </span>
            <p>Mango DB</p>
          </div>
          <div className="clearfix"></div>
        </Col>
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img
                src={screenwriting}
                className="portimg"
                alt="Survey Project"
              />{" "}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={vexanime} className="portimg" alt="Vexanime Project" />{" "}
            </div>
          </div>
        </Col>
        <Col md={7} className="mt-3 ps-5">
          <h2 className="mt-5">Vexanime Project</h2>
          <p className="pe-5">
            {" "}
            The project involved creating a streaming platform called Vex Anime
            for anime episodes. The aim of the project was to provide anime
            enthusiasts with a convenient and easy-to-use platform to watch
            their favorite anime series online. The team focused on developing
            an intuitive user interface with various features, including a
            search bar, a list of recommended anime series, and a section for
            new releases. Additionally, the team implemented a
            subscription-based model for premium content, which gave users
            exclusive access to certain anime series and additional features.
            The team conducted extensive testing and collaborated with anime
            distributors to ensure that the website's functionality and content
            were legally obtained and in compliance with copyright laws. The
            project resulted in a successful and valuable tool for anime lovers,
            providing them with a seamless streaming experience and access to a
            vast selection of anime series.{" "}
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={angular} alt="angular" />
            </span>
            <p>Angularjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={dotnet} alt="dotnet" />
            </span>
            <p>ASP.NET MVC</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={7} className="mt-3">
          <h2 className="mt-5">Social-UID Project</h2>
          <p className="pe-5">
            The project was a unique and innovative offering that brought
            together social media, news, and OTT platforms in one place. With
            this approach, users could access multiple platforms and their
            respective content without having to switch between different
            applications. The social media platform enabled users to connect
            with their friends and family, share their thoughts and keep up with
            the latest trends. The news channel platform provided a
            comprehensive range of news, while the dedicated OTT platform
            offered an extensive library of movies, TV shows, documentaries, and
            other content. This integrated approach simplified the user
            experience, making it easier for them to discover new content,
            engage with others, and stay informed. The project was a significant
            step toward creating a more connected and efficient media landscape.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={angular} alt="angular" />
            </span>
            <p>Angularjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={python} alt="python" />
            </span>
            <p>Django</p>
          </div>
          <div className="clearfix"></div>
        </Col>
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img
                src={socialuid}
                className="portimg"
                alt="Social-UID Project"
              />{" "}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img
                src={classifiedad}
                className="portimg"
                alt="Classified AD Project"
              />{" "}
            </div>
          </div>
        </Col>
        <Col md={7} className="mt-3 ps-5">
          <h2 className="mt-5">Classified AD Project</h2>
          <p className="pe-5">
            {" "}
            Genuine information is still not readily accessible to a less
            accomplished public. While talent could be found in hidden
            deep-pocketed regions, the lack of information about such talent
            becomes a bottleneck in their development. We at CROSSLINKER are
            pledged to bring deeply hidden information to hard-to-reach people
            and places. Consider CROSSLINKER as a philanthropist company that
            would like to help those who want to make a positive contribution
            and impact on human society. The concept of CROSSLINKER was designed
            by a high school student with the vision to make this data-driving
            world safer & secure that offering life-transforming information
            accessible to every part of the globe. Too much information creates
            confusion and therefore having precise information will be critical
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={python} alt="python" />
            </span>
            <p>Django</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={7} className="mt-3">
          <h2 className="mt-5">Tour Plan</h2>
          <p className="pe-5">
            {" "}
            The project involved creating a website that simplified trip
            planning for customers. The website provided information on
            transportation options and travel times to reach destinations, along
            with details on popular attractions and accommodations. The team
            integrated various transportation systems' data and tested the
            website for accuracy and reliability. The goal was to make trip
            planning easy and stress-free for customers.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={node} alt="node" />
            </span>
            <p>Nodejs</p>
          </div>

          <div className="clearfix"></div>
        </Col>
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={tourplan} className="portimg" alt="Tour Plan" />{" "}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={zoomclone} className="portimg" alt="Zoom Clone Web" />{" "}
            </div>
          </div>
        </Col>
        <Col md={7} className="mt-3 ps-5">
          <h2 className="mt-5">Zoom Clone Web</h2>
          <p className="pe-5">
            {" "}
            The project was a React-based website that cloned all the features
            of Zoom.com, including video conferencing, screen sharing,
            recording, and chat. It provided a reliable video conferencing
            experience with high-quality audio and video, as well as
            collaboration tools like screen sharing and chat. The platform was a
            comprehensive solution for businesses and individuals looking for a
            video conferencing tool similar to Zoom.com.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={python} alt="python" />
            </span>
            <p>Django</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={7} className="mt-3">
          <h2 className="mt-5">Admin Panel Project</h2>
          <p className="pe-5">
            The project was a store management system that I developed using
            React. It was designed to help salespersons track customer spending,
            phone numbers, and activities, and included a campaign feature for
            creating offers and promotions. My focus was on creating an
            intuitive and easy-to-use front end for the client. The store
            management system provided a powerful tool for businesses to track
            customer behavior and implement targeted marketing campaigns to
            increase sales. The project's robust features and user-friendly
            interface made it a valuable asset for salespersons looking to
            optimize their sales strategy and increase revenue.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={node} alt="node" />
            </span>
            <p>Nodejs</p>
          </div>

          <div className="clearfix"></div>
        </Col>
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img
                src={adminpanel}
                className="portimg"
                alt="Admin Panel Project"
              />{" "}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={radioproject} className="portimg" alt="Radio Project" />{" "}
            </div>
          </div>
        </Col>
        <Col md={7} className="mt-3 ps-5">
          <h2 className="mt-5">Radio Project</h2>
          <p className="pe-5">
            The project Radio Go was built using React and Python, offering
            users access to over 2000 Greek radio stations featuring various
            genres of music, talk shows, and sports coverage. Its use of React
            allowed for a fast and responsive user experience, while Python
            provided a reliable infrastructure for managing and organizing the
            collection of stations. The platform's search and filtering features
            make it easy for users to find the perfect station for their
            listening preferences.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={python} alt="python" />
            </span>
            <p>Django</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5">
        <Col md={7} className="mt-3">
          <h2 className="mt-5">Screenwriting Project</h2>
          <p className="pe-5">
            The project was a web-based application that I built using vanilla
            JavaScript. It allowed users to create scripts for dramas, movies,
            or other productions. It featured customizable templates, automatic
            formatting, and a built-in script analysis tool for refining and
            perfecting scripts. Users could create character profiles, manage
            scenes, and collaborate with a team in real time. The project
            provided an easy-to-use platform for writers of all skill levels,
            with features that streamlined the writing process and ensured
            accuracy.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={js} alt="js" />
            </span>
            <p>Javascript</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>reacjs </p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={python} alt="python" />
            </span>
            <p>Django</p>
          </div>

          <div className="clearfix"></div>
        </Col>
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img
                src={screenwriting2}
                className="portimg"
                alt="Screenwriting Project"
              />{" "}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Webapp;
